
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.share {

    span {
        vertical-align: top;
        display:inline-block;
        height:36px;    
        line-height:36px;
    }

    .icon-svg.icon-twitter-alt {
        background-image: url('/assets/img/icons/social/icon-twitter-alt.svg');
        background-size:17px 17px;
    }

    .icon-svg.icon-facebook-alt {
        background-image: url('/assets/img/icons/social/icon-facebook-alt.svg');
        background-size:16px 16px;
    }

    .icon-svg.icon-linkedin-alt {
        background-image: url('/assets/img/icons/social/icon-linkedin-alt.svg');
        background-size:16px 16px;
    }


    .icon-svg.icon-twitter-alt,
    .icon-svg.icon-facebook-alt,
    .icon-svg.icon-linkedin-alt {
        margin-left:5px;
        background-color:$theme-brown-color-1;
        background-position: center center;
        line-height:36px;
        width:36px;
        height:36px;
        border-radius:6px;
        white-space:nowrap;
        overflow:hidden;
        color:transparent;
        display:inline-block;
    }
}
