
/**
 * scss/core/components/block-image-text.scss
 * ----------------------------------------------------------
 * Block with an image and text and optional footer
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.block-image-text {

    display:block;
    background: $theme-brand-color-light;
    margin-bottom: 20px;

    .image {
        overflow:auto; /* IE 11 */
        img {
            margin:0px
        }
    }

    div.text,
    div.footer {
        padding: $theme-block-padding-sm;
        color: $theme-body-font-color;
    }
    div.text + div.footer {
        padding-top:0;
    }

    p:last-child {
        margin-bottom:0;
    }
    
    /* needed for when the complete block is a href */
    h1, h2, h3, h4, h5, p, blockquote {
        display:inline-block;
        width:100%;
    }
}

@media (min-width: $theme-screen-md) {

    /* double padding option set from Umbraco docType */
    .block-image-text[data-double-padding="True"] {
        $double-padding: $theme-block-padding-md * 2;
        div.text,
        div.footer {
            padding: $double-padding;
        }
        div.text + div.footer {
            padding-top:0;
        }
    }

    .block-image-text {
        div.text,
        div.footer {
            padding: $theme-block-padding-md;
        }
    }
}

[data-section-flex="true"] {
    .row {
        .column {
            > div {
                width:100%;
                display:flex;
            }
            .block-image-text {
                display:flex;
                flex-direction: column;
                width:100%;
                overflow:hidden;

                div.footer {
                    position:relative;
                    /* both are needed for IE11 */
                    margin-top:auto;
                    justify-content: flex-end;
                }
            }
        }
    }
}

/**
 * Header variant
 * ----------------------------------------------------------
 */
.header-image .block-image-text,
.header-image .block-image-text.primary {
    background: rgba(255,255,255,0.2) 
}

.header-image .block-image-text.secondary {
    background: black;
}

/**
 * Style variants
 * ----------------------------------------------------------
 */

.block-image-text,
.block-image-text.primary {

}

.block-image-text.secondary {
    background: transparent;
    div.footer,
    div.text {
        padding-left:0;
        padding-right:0;
    }
}
