
/**
 * scss/core/components/alert.scss
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.box {
    background: lighten($theme-brand-color-primary, 70);
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 4px;
}