
/**
 * scss/core/components/infinite-scroll.scss
 * ----------------------------------------------------------
 * Infinite scroll component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.infinite-scroll {
    position: relative;

    .column {
        min-height: 0;

        .article {
            display: none;
            background: black;
            border: 1px solid black;
        }
        .article.article-fade {
            display: block;

            animation-name: fadeIn;
            -webkit-animation-name: fadeIn;

            animation-duration: 0.5s;
            -webkit-animation-duration: 0.5s;
        }
    }
}
.infinite-scroll.is-loading {
    padding-bottom: 40px;
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    20% { opacity: 0; }
    40% { opacity: 0.3; }
    60% { opacity: 0.5; }
    80% { opacity: 0.9; }
    100% { opacity: 1; }
}