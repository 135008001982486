
/**
 * scss/core/core.scss
 * ----------------------------------------------------------
 * Core framework...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* util
------------------------------------------------------------ */
@import 'util/mixins';

/* util
------------------------------------------------------------ */
@import 'theme/theme';

/* base
------------------------------------------------------------ */
@import "base/angular";
@import "base/base";
@import "base/body";
@import "base/button";
@import "base/form";
@import "base/heading";
@import "base/hyperlink";
@import "base/icon";
@import "base/images";
@import "base/list";
@import "base/sixteen-nine";
@import "base/spacers";

/* components
------------------------------------------------------------ */
@import 'components/accordion';
@import 'components/alert';
@import 'components/box';
@import 'components/breadcrumb';
@import 'components/cookie-bar';
@import 'components/hamburger';
@import 'components/iframe';
@import 'components/infinite-scroll';
@import 'components/map';
@import 'components/modal';
@import 'components/nav';
@import 'components/pagination';
@import 'components/spinner';
@import 'components/tooltip';
@import 'components/block';
@import 'components/on-appear';
@import 'components/youtube-player';

/* layout
------------------------------------------------------------ */
@import 'layout/footer';
@import 'layout/grid';
@import 'layout/header';