
/**
 * scss/core/components/cookie-bar.scss
 * ----------------------------------------------------------
 * Cookie bar component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-cookie-bar-background: $theme-brand-color-gray-1;
$theme-cookie-bar-font-size: 14px !default;

// -----------------------------------------------------------------------------------------
// state classes
// -----------------------------------------------------------------------------------------

.cookie-bar.cb-hide {
    bottom: -100%;
}

.cookie-bar.cb-active {
    display: block;
}

.cookie-bar {

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;

    transition: all 0.2s ease-in-out;
    background: $theme-cookie-bar-background;
    display: none;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 999;
    text-align: center;

    .message {
        display: inline-block;
        padding: 20px;
        font-size: $theme-cookie-bar-font-size;
        text-align: center; 

        p {
            // margin-bottom: 1em; 
            // font-size: $theme-cookie-bar-font-size;
        }

        .btn {
            // padding: 5px 12px;
            // font-size: 12px;
        }

        .btn.btn-none {
          // padding: 8px 0;
        }

        .btn + .btn {
            margin-left: 7px;
        }
    }
}

/**
 *
 * Media query
 * ----------------------------------------------------------
 * Handles responsive queries for Medium devices
 *
 * @width       992pixels
 * @container   970pixels
 *
 */
@media (min-width: $theme-screen-md) {
    .cookie-bar {
        .message {
            padding: 20px 15px;
            max-width: 970px;
        }
    }
} 