
/**
 * core/base/hyperlink.scss
 * ----------------------------------------------------------
 * Hyperlink classes
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  20178Uselab.com
 *
 */

a {
    &,
    &:focus,
    &:hover,
    &:active,
    &:active:focus {
        color: $theme-brand-color-blue-1;
    }
    &,
    &:focus,
    &:hover,
    &:active,
    &:active:focus {
        text-decoration:underline;
    }
}

.link-telephone, .link-email {
    display: inline-block;
    padding-left: 34px;
    position: relative;
    line-height: 34px;
}

.link-telephone::before, .link-email::before {
    @include vertical-align();
    display: block;
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
}

.link-telephone::before {
    background: url(/assets/img/icons/contact/icon-mobile.svg) 0 0 no-repeat;
}

.link-email::before {
    background: url(/assets/img/icons/contact/icon-mail.svg) 0 0 no-repeat;
}

.link-download,
a.link-download, 
a.link-download:not([href]) {
    color: $theme-brand-color-blue-1;
    background: url(/assets/img/icons/icon-download.svg) 0 5px no-repeat;
    background-size: 16px 16px;
    display:inline-block;
    padding-left:30px;
    font-weight:600;
    text-decoration: none;

    &:hover {
        // text-decoration: none;
    }
}

.link-arrow,
a.link-arrow, 
a.link-arrow:not([href]) {
    
    color: $theme-brand-color-blue-2;

    &:before {
        position: relative;
        margin-right:13px;
        content: "";
        display: inline-block;
        width: 9px;
        height: 9px;
        border-right: 2px solid black;
        border-top: 2px solid black;
        transform: rotate(45deg);
    }

    display:inline-block;
    text-decoration: none;

    &:hover {
        // text-decoration: underline;
        // color:black;
    }
}

.link-arrow-2,
a.link-arrow-2, 
a.link-arrow-2:not([href]) {
    
    color: $theme-brand-color-blue-1;

    &:after {
        position: relative;
        margin-left:8px;
        content: "";
        display: inline-block;
        width: 9px;
        height: 9px;
        border-right: 2px solid black;
        border-top: 2px solid black;
        transform: rotate(45deg);
    }

    display:inline-block;
    font-weight:600;
    text-decoration: none;

    &:hover {
        // text-decoration: underline;
        // color:black;
    }
}

/* fix for Umbraco editor, easier to create a list of download links when you can use <p> tags for a new line and not get the <p> margin */
p {
    > a.link-download,
    > a.link-arrow,
    > a.link-arrow-2 {       
        list-style:none;
        margin-bottom:-1em;
        display: list-item;  /* fix for negative margin not working on */

        &:not([href]) { 
            list-style:none;
            margin-bottom:-1em;
            display: list-item;  /* fix for negative margin not working on */
        }
    }
}
