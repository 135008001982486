
/**
 * scss/core/components/modal/danger.scss
 * ----------------------------------------------------------
 * Modal danger component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.modal {
    .modal-danger {
        .modal-header {
            h2 {
                color: $theme-modal-color-danger;
            }
        }
    }
}