
/**
 * scss/core/components/accordion.scss
 * ----------------------------------------------------------
 * Accordion component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-accordion-toggle-sprite: '/assets/img/accordion-toggle-sprite.svg' !default;

.expandable-content {
    height: 0;
    overflow: hidden;
    padding-left: 26px;
}

.expandable-content:after {
    content: '';
    display: block;
    height: 1em;
}

.expandable-content.animated {
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
}

.expandable.expanded > .expandable-content {
    height: auto;
}

.expandable-toggle {
    position: relative;
    cursor: pointer;
    padding: 3px 26px;
    display: block;
}

.expandable-toggle:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: url($theme-accordion-toggle-sprite) -5px top no-repeat;
}

.expanded > .expandable-toggle:before {
    background-position: -5px -24px;
}