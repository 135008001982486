
/**
 * scss/site/components/vue/component-base
 * ----------------------------------------------------------
 * Handles vue placeholder styles
 *
 * It works like this:
 *
 * Vue lazy-loads the components, therefore, the CSS of the component
 * is not yet available before the component is loaded and rendered.
 * 
 * So, we have a .vue__placeholder div that shows before the component is loaded.
 * This placeholder uses the CSS that is defined in vue\componentname\_placeholder.scss
 * and is compiled into the main CSS bundle (so try to keep it small!)
 *
 * The CSS for the real Vue component is defined in vue\componentname\componentname.scss
 * and is not compiled in to the main bundle (no underscore in the filename) but a separate
 * CSS file that is imported in the Vue component (.vue) so it is only loaded when it's needed
 *
 * Example markup:
 *
 * <div class="test-component">
 *   <test-component class="vue__component" data-v-cloak></test-component>
 *   <div class="vue__placeholder">
 *      <span>Vue placeholder content</span>
 *   </div>
 * </div>
 *
 * @type       Sass
 * @copyright  2019 Uselab.com
 *
 */

/* component placeholders
------------------------------------------------------------ */
@import "test-component/placeholder";

/* component loading pulse animation
------------------------------------------------------------ */
@keyframes pulse {
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.7;
  }
}

/* vue component placeholder default styles
 * only shown when a component is loading
------------------------------------------------------------ */
.vue__placeholder {
  animation: pulse 1.5s infinite;
  display: block;

  .text-placeholder {
    border-radius: 3px;
    background: $theme-brand-color-gray-2;
    width: 100%;
  }
}

/* hides placeholder when the component is loaded
------------------------------------------------------------ */
.vue__component + .vue__placeholder {
  display: none;
}

/* shows placeholder when the component is loading
------------------------------------------------------------ */
.vue__component[data-v-cloak] + .vue__placeholder,
.vue__component[v-cloak] + .vue__placeholder {
  display: block;
}

.vue__component[data-v-cloak],
.vue__component[v-cloak] {
  display: none;
}

/* vue component placeholder inside the Umbraco editor
------------------------------------------------------------ */
.ng-scope .vue__placeholder {
  position: relative;
  padding: 14px 20px 20px 45px;
  animation: none;
  border: 2px dotted $theme-brand-color-gray-2;
  text-align: left;
  width: 100%;
  min-height: 250px;
  // hide all content inside placeholder
  * {
    display: none;
  }

  &:after {
    content: 'Vue component';
    background: url('/Assets/img/icons/icon-globe.svg') no-repeat left top;
    position: absolute;
    text-indent: 35px;
    line-height: 23px;
    left: 10px;
    top: 10px;
    display: block;
    min-width: 23px;
    height: 23px;
    white-space: nowrap;
    overflow: hidden;
    overflow: ellipsis;
  }
}
