
/**
 * scss/core/base/body.scss
 * ----------------------------------------------------------
 * Body styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

body {
  overflow-x: hidden;
}

body, body.mce-content-body {
  background: $theme-body-background;
  font-family: $theme-body-font-family;
  font-size: $theme-body-font-size;
  color: $theme-body-font-color;
}