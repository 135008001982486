
/**
 * scss/core/base/form/input.scss
 * ----------------------------------------------------------
 * File input container...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab.com
 *
 */


.input-container {

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $theme-form-color-placeholder;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $theme-form-color-placeholder;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $theme-form-color-placeholder;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $theme-form-color-placeholder;
    }

    background: white; // todo: should be a variable!
    border: $theme-form-element-border solid $theme-form-color-default;
    border-radius: $theme-form-element-radius;

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="file"],
    input[type="number"] {
        background: transparent;
        border: 0;
        padding: $theme-form-element-padding;
        font-size: $theme-body-font-size;
        width: 100%;
    }
}

.input-container.input-error {
    border-color: $theme-form-color-error;
}