/**
 * scss/core/components/cookie-bar.scss
 * ----------------------------------------------------------
 * Cookie bar component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Fenna Veenstra       <f.veenstra@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

#CybotCookiebotDialog, #cookieBanner {
    display: none !important; // hide the cookieinfo cookiebanner!
}

.cookiebar {
    background: $theme-brand-color-primary;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;

    .cookie-message {
        padding: 15px;
        color: white;

        a {
            color: white;
            font-weight: 700;
        }

        .btn {
            font-size: 16px;
        }
    }

    .cookie-configuration {
        display: none;
        background-color: white;
        position: fixed;
        background: white;
        height: auto;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10002;
        max-height: 100vh;
        overflow: scroll;

        font-size: 14px;
        @media (min-width: $theme-screen-md) {
            width: 80%;
            background: white;
            margin-top: 5%;
            margin: 0 auto;
            top: 5%;
            bottom: auto;
            overflow: initial;
            font-size: 16px;
        }

        @media (min-width: $theme-screen-lg) {
            width: 750px;
        }

        &-option {
            border-radius: 0;
            transition: all 0.2s ease;

            &.is-selected,
            &:hover {
                cursor: pointer;
                border: 1px solid $theme-brand-color-blue-3;

                .cookie-configuration {
                    &-option {
                        &-rating {
                            .icon-star {
                                background-position-y: -22px;
                            }
                        }
                    }
                }
            }

            &-rating {
                .icon-star {
                    background: url(/assets/img/icons/icon-star.svg) 0 0 no-repeat;
                    background-size: 22px 44px;
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    &.is-visible {
        background: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10001;

        .cookie-message {
            display: none;
        }

        .cookie-configuration {
            display: block;

            &-option {
                display: block;

                &-rating {
                }
            }
        }
    }

    &.is-hidden {
        display: none;
    }
}
