
/**
 * scss/core/base/list.scss
 * ----------------------------------------------------------
 * Base list styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

// removed, needs more specific targeting!
// .column {
//    ul:not(.pagination) {
//        padding: 0 0 2em 1em;
//    }
//    ul:not(.pagination) {
//        margin-top: 0;
//        margin-bottom: 10px;
//    }
//}
// list inline
ul.list-inline {
    @include ul-reset();

    li {
        display: inline-block;
    }
}

// list with divider
ul.list-divider {
    li {
        display:inline-block;
        padding-right: 30px;
        margin-right: 10px;
        position: relative;
    }
    li:after {
        content: '';
        display: block;
        position: absolute;
        background: white;
        width: 1px;
        height: 100%;
        right: 0;
        top: 0;
    }
    li:last-child:after {
        display: none;
    }
}

// list base types
ul.list-primary { @include ul-default(false, 6px, $theme-ul-primary, '', 8px); }
ul.list-secondary { @include ul-default(false, 6px, $theme-ul-secondary, '', 8px); }
ul.list-third { @include ul-default(false, 6px, $theme-ul-third, '', 8px); }
ul.list-arrow { @include ul-default(false, 12px, '', $theme-ul-arrow-image, 5px); }
