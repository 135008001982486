
/**
 * scss/core/base/base.scss
 * ----------------------------------------------------------
 * Base, generic and global styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* reset button focus
------------------------------------------------------------ */
button:focus {
    outline: 0;
}

p {
    margin-bottom:1.5em;
}