
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

header, header .column, .dark-theme {

    background-color:$theme-brand-color-gray-3;

    p, span, h1, h2, h3, h4, h5 {
    //    color:white;
    //   text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    }
}

header {
    min-height:220px;
    position:relative;

    .align-bottom {
        transform: translateY(100%);
    }
}

/* gradient, not needed atm

header:after {
    content:'';
    position:absolute;
    top:0;
    display:block;
    width:100%;
    height:100%;
    background:rgba(0, 0, 0, 0.4);
    z-index:-1;
}

*/


@import 'header-home';
@import 'header-error';
@import 'header-collage';
@import 'header-text-image';
@import 'header-image';
@import 'header-full-width'; 