
/**
 * scss/core/components/alert/warning.scss
 * ----------------------------------------------------------
 * Alert warning component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.alert.alert-warning {
    background: $theme-alert-color-warning;
    border-color: $theme-alert-color-warning;
    color: white;
}