
/**
 * scss/core/components/alert.scss
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

@import "block/block-image-text";
@import "block/block-text-on-image";