
/**
 * scss/core/layout/footer.scss
 * ----------------------------------------------------------
 * Footer layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

#footer {

    background: $theme-footer-background;
    padding: 40px 0;

    ul {
        @include ul-reset();
        margin-bottom:1.5em;
    }

    .row.terms-and-conditions {

        padding-top:20px;

        ul {
            display:inline-block;
            li {
                display:inline-block;
                margin-right:20px;
                font-size:$theme-font-size-s;
            }
            margin-bottom:0;
        }
    }
}