
/**
 * scss/core/base/form/input-search.scss
 * ----------------------------------------------------------
 * File input...
 *
 * @type       Sass
 * @author     PJ Arts    <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */


.input-container.input-search {
    position: relative;
    height: 46px;

    input[type="text"] {
        width: 100%;
        height: 100%;
        padding: 0 50px 0 10px;
        border: 0;
        position: absolute;
    }
    input[type="submit"] {
        position: absolute;
        z-index: 2;
        right:0;
        width:42px;
        height:42px;
        text-indent:42px;
        overflow:hidden;
    }
}