
/**
 * scss/core/icon/base
 * ----------------------------------------------------------
 * Base icon definitions...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* inline text
------------------------------------------------------------ */
// note: fix for icons in text breaking the line height...
.icon-inline-text {
    margin-top: -0.5em;
    margin-bottom: -0.5em;
}

/* icon margins
------------------------------------------------------------ */
.icon-margin-r { margin-right: 5px; }
.icon-margin-l { margin-left: 5px; }