
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

header.header-collage {

    min-height:0;
    background:none;

    .container {
        padding-top:20px;
        padding-bottom:0;
    }

    div.collage {

        height:250px;

        h1 {
            margin:0;
        }

        .align-bottom {
            position:absolute;
            bottom:30px;
            left:30px;
            transform:none;
        }

        .main {
            position:relative;
            height:100%;
            width:100%;
            margin-right:10px;
            float:left;
            padding: 30px;
        }

        .small {
            display:none;
        }
    }
}


@media (min-width: $theme-screen-md) {

    header.header-collage {

        div.collage {

           height:430px;

           .main {
               height:100%;
               width:calc(75% - 10px);
               margin-right:10px;
               float:left;
               padding: 30px;
           }

           .small {
               width:calc(25% - 10px);
               height:calc(50% - 10px);
               margin-left:10px;
               margin-bottom:10px;
               float:left;
               display:block;
           }
       
           .small:last-child {
                margin-bottom:0px;
                margin-top:10px;
           }
        }
    }
}

@media (min-width: $theme-screen-lg) {
    header.header-collage {
        div.collage {
           height:570px;
        }
    }
}