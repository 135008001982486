
/**
 * scss/core/base/form/findicates-required.scss
 * ----------------------------------------------------------
 * Indicates required...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab.com
 *
 */

.indicates-required {
    display: inline-block;
    font-size: $theme-font-size-xs;
    margin-top: 5px;
    position: relative;
    text-indent: 1000px;
    overflow: hidden;
    width: 10px;
    height: 10px;
}

.indicates-required:before {
    @include vertical-align();
    content: '';
    background: $theme-form-required-color;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 0;
}