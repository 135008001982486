
/**
 * scss/core/components/
 * ----------------------------------------------------------
 * employees component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.block-employees { 

    background: transparent;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    
    .block-employee {
        
        a {
            display:block;
        }
        
        .link-arrow {
            margin-bottom: 0;
        }

        h3  {
            display:inline-block;
            width:100%;
        }

        .vertical-align {
            min-height:80px;
        }

        padding:30px 30px 30px 130px;
        position:relative;

        img {
            width:80px;
            height:80px;
            position:absolute;
            left:30px;
            top:30px;
        }
    }

    div.column {
        margin-bottom: 0;
    }
    
    .column:not(:first-child) .block-employee {
        padding-top:0;
        img {
            top:0px;
        }
    }

}

@media (min-width:  map-get($grid-breakpoints, "md")) {

    .block-employees { 
        .block-employee {

            .vertical-align {
                min-height:100px;
            }
            padding:30px 30px 30px 150px;

            img {
                width:100px;
                height:100px;
            }
        }
    }
}

@media (min-width:  map-get($grid-breakpoints, "xl")) {

    .block-employees { 
        .block-employee {

            .vertical-align {
                min-height:130px;
            }

            img {
                width:130px;
                height:130px;
            }

            padding:30px 30px 30px 180px;

            img {
                top:30px;
            }

        }
        .column:not(:first-child) .block-employee {
            padding-top:30px;
            img {
                top:30px;
            }
        }
    }
}