
/**
 * scss/core/base/
 * ----------------------------------------------------------
 * Angular selectors/classes/styles...
 *
 * @type       Sass
 * @author     PJ Arts   <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */


.sixteen-nine {
    @include sixteen-nine('.content', true, true);
}