﻿
/**
 * Cookie bar
 * ----------------------------------------------------------
 * Stylesheet for cookie bar...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @author     Fenna Veenstra       <f.veenstra@uselab.com>
 * @copyright  2014 Uselab.com
 *
 */

.CookieDeclarationDialogText, .CookieDeclarationIntro, .CookieDeclarationLastUpdated {
    margin: 0 0 14px 0
}

.CookieDeclarationType {
    display: block;
    margin: 12px 0 12px 0;
    padding: 8px 8px 0 8px;
    border: 1px solid #333333;
    vertical-align: top;
}

.CookieDeclarationTypeHeader {
    font-weight: bold;
}

.CookieDeclarationTypeDescription {
    margin: 2px 0 16px 0;
}

.CookieDeclarationTable {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 18px 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

.CookieDeclarationTableHeader {
    font-weight: bold;
    border-bottom: 1px solid #777777;
    text-align: left;
    padding: 4px;
}

.CookieDeclarationTableHeader[dir='rtl'] {
    text-align: right;
}

.CookieDeclarationTableCell {
    text-overflow: ellipsis;
    word-wrap: break-word;
    border-bottom: 1px solid #777777;
    vertical-align: top;
    padding: 4px 4px 5px 4px;
}

.scroll-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.CookieDeclarationTable {
    min-width: 420px;
}

@media (max-width: $theme-screen-sm) {
    .CookieDeclarationTable {
        * {
            font-size: 14px;
        }
    }
}

.CookieDeclarationTypeHeader {
    @extend .h2;
    font-size: 24px;
}

.CookieDeclarationUserStatusPanel,
.CookieDeclarationIntro {
    margin: 0 !important;
}

.CookieDeclarationIntro + p {
    padding: 0 !important;
}

.CookieDeclarationType {
    padding: 0 !important;
    margin: 0 0 25px 0 !important;
    border: 0 !important;

    & + & {
        padding-top: 5px !important;
    }
}

.CookieDeclarationTypeDescription {
    margin: 0 !important;
    padding-bottom: 25px !important;
}

.CookieDeclarationTable {
    margin: 0 !important;
}

.table.CookieDeclarationTable {
    max-width: none;
}
