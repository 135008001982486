
/**
 * data-section-flex
 * ----------------------------------------------------------
 * Custom flex grid and Bootstrap grid styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 * Important note:
 * ----------------------------------------------------------
 * We depend on the Bootstrap .col-**-** structure!
 *
 */

[data-section-flex="true"] {
    .row.row-header {
        .column {
            > div {
                display: block;
            }
        }
        .column + .column {
            margin-top: 10px;
        }
    }
}

@media (min-width: $theme-screen-md) {
    [data-section-flex="true"] {
        .row.clearfix:before {
            display: none; /* fix bug for ipad */
        }

        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .column {
                display: flex;

                > div {
                    display: flex;
                }

                > div.image {
                    display: block;
                }
            }
        }

        .row.row-header {
            .column {
                margin-top: 0;

                > div {
                    display: block;
                    padding: 0 0 30px 0;
                }
            }
        }
    }
}
