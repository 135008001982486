
/**
 * scss/core/components/modal.scss
 * ----------------------------------------------------------
 * Modal component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-modal-backdrop-color:                    #1d1d1d !default;
$theme-modal-color-default:                     black !default;
$theme-modal-color-info:                        $theme-color-state-info !default;
$theme-modal-color-success:                     $theme-color-state-success !default;
$theme-modal-color-warning:                     $theme-color-state-warning !default;
$theme-modal-color-danger:                      $theme-color-state-danger !default;

.modal-backdrop {
    background-color: $theme-modal-backdrop-color;
}

.modal-backdrop.in {
    opacity: 0.4;
}

.modal {
    .modal-content {
        border: 0;
        border-radius: 0;

        .modal-inner {
            .modal-header,
            .modal-body,
            .modal-footer {
                border: 0;
            }
            .modal-header {
                padding: 30px 15px 15px 30px;

                h2, h3, h4, h5 {
                    margin: 0;
                }
            }
            .modal-body {
                padding: 0 30px;
            }
            .modal-footer {
                padding: 30px 30px 30px 30px;
            }

            .modal-form {
                padding: 15px;
            }
            .modal-footer {
                text-align: left;

                ul {
                    @include ul-reset();

                    li {
                        display: inline-block;
                        margin: 0 10px 0 0;

                        button {
                            outline: 0;
                        }
                    }
                    li:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

/**
 *
 * Media query
 * ----------------------------------------------------------
 * Handles responsive queries for Medium devices
 *
 * @width       780pixels
 * @container   750pixels
 *
 */

@media (min-width: $theme-screen-sm) {
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-inner {
                    margin: 0 75px 0 75px;
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------
// import
// -----------------------------------------------------------------------------------------

@import "modal/danger";
@import "modal/default";
@import "modal/info";
@import "modal/success";
@import "modal/warning";
