
/**
 * scss/core/components/iframe.scss
 * ----------------------------------------------------------
 * Iframe component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.umb-cell-embed, // for correct display in grid editor
.iframe-wrapper,
.iframe-wrapper[data-ratio="16:9"] {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

