
/**
 * scss/core/base/heading.scss
 * ----------------------------------------------------------
 * Base stylesheet for heading styles and classes...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* heading defaults
------------------------------------------------------------ */
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5 {
    @include heading-reset();
}

/* heading fonts
------------------------------------------------------------ */
h1,.h1, h1 * { font-family: $theme-h1-font-family; }
h2,.h2, h2 * { font-family: $theme-h2-font-family; }
h3,.h3, h3 * { font-family: $theme-h3-font-family; }
h4,.h4, h4 * { font-family: $theme-h4-font-family; }
h5,.h5, h5 * { font-family: $theme-h5-font-family; }

/* heading sizes
------------------------------------------------------------ */
h1,.h1, h1 * { font-size: $theme-h2-font-size; }
h2,.h2, h2 * { font-size: $theme-h3-font-size; }
h3,.h3, h3 * { font-size: $theme-h4-font-size; }
h4,.h4, h4 * { font-size: $theme-h5-font-size; }
h5,.h5, h5 * { font-size: $theme-h5-font-size; }

/* heading weights
------------------------------------------------------------ */
h1,.h1, h1 * { font-weight: $theme-h1-font-weight; }
h2,.h2, h2 * { font-weight: $theme-h2-font-weight; }
h3,.h3, h3 * { font-weight: $theme-h3-font-weight; }
h4,.h4, h4 * { font-weight: $theme-h4-font-weight; }
h5,.h5, h5 * { font-weight: $theme-h5-font-weight; }

/* ------- medium screens ------- */
@media (min-width: $theme-screen-md) {
    h1,.h1, h1 * { font-size: $theme-h1-font-size; }
    h2,.h2, h2 * { font-size: $theme-h2-font-size; }
    h3,.h3, h3 * { font-size: $theme-h3-font-size; }
    h4,.h4, h4 * { font-size: $theme-h4-font-size; }
    h5,.h5, h5 * { font-size: $theme-h5-font-size; }
}


/* heading colors
------------------------------------------------------------ */
h1 a, h1 a:hover, h1 a:active, h1 a:link, h1, .h1, .h1 * { color: $theme-h1-font-color; }
h2 a, h2 a:hover, h2 a:active, h2 a:link, h2, .h2, .h2 * { color: $theme-h2-font-color; }
h3 a, h3 a:hover, h3 a:active, h3 a:link, h3, .h3, .h3 * { color: $theme-h3-font-color; }
h4 a, h4 a:hover, h4 a:active, h4 a:link, h4, .h4, .h4 * { color: $theme-h4-font-color; }
h5 a, h5 a:hover, h5 a:active, h5 a:link, h5, .h5, .h5 * { color: $theme-h5-font-color; }


h1 a, h1 a ,h2 a, h3 a, h4 a {
//    text-decoration: underline;
}

/* heading margins
------------------------------------------------------------ */
h1, .h1 { margin: 0 0 0.70em 0; }
h2, .h2 { margin: 0 0 0.50em 0; }
h3, .h3 { margin: 0 0 0.50em 0; }
h4, .h4 { margin: 0 0 0.50em 0; }
h5, .h5 { margin: 0 0 0.50em 0; }
