
/**
 * scss/core/base/spacers/padding.scss
 * ----------------------------------------------------------
 * Padding spacers...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* top
------------------------------------------------------------ */
.padding-t0 { padding-top: 0 !important; }
.padding-t05 { padding-top: 5px !important; }
.padding-t10 { padding-top: 10px !important; }
.padding-t15 { padding-top: 15px !important; }
.padding-t20 { padding-top: 20px !important; }
.padding-t25 { padding-top: 25px !important; }
.padding-t30 { padding-top: 30px !important; }
.padding-t40 { padding-top: 40px !important; }
.padding-t60 { padding-top: 60px !important; }
.padding-t70 { padding-top: 70px !important; }

/* bottom
------------------------------------------------------------ */
.padding-b0 { padding-bottom: 0 !important; }
.padding-b05 { padding-bottom: 5px !important; }
.padding-b10 { padding-bottom: 10px !important; }
.padding-b15 { padding-bottom: 15px !important; }
.padding-b20 { padding-bottom: 20px !important }
.padding-b25 { padding-bottom: 25px !important; }
.padding-b30 { padding-bottom: 30px !important; }
.padding-b40 { padding-bottom: 40px !important; }
.padding-b60 { padding-bottom: 60px !important; }
.padding-b70 { padding-bottom: 70px !important; }