
/**
 * scss/core/base/images.scss
 * ----------------------------------------------------------
 * Image classes...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

img.slimsy {
    max-width: 100%;
    height: auto!important;
    margin-bottom: 1em;
}