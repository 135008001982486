
/**
 * scss/core/base/form/option.scss
 * ----------------------------------------------------------
 * Option [radio/checkbox] styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.form-row {
    .option-container {
        margin-bottom: -6px;
    }

    input[type=radio],
    input[type=checkbox] {
        position: absolute;
        visibility: hidden;
    }

    input[type=radio] + label,
    input[type=checkbox] + label {
        position: relative;
        display: block;
        padding: 4px 20px 6px 30px;
        margin: 0;
        min-height: $theme-form-element-height;
    }

    input[type=radio] + label:before,
    input[type=checkbox] + label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: $theme-form-element-height;
        height: $theme-form-element-height;
    }

    input[type=radio] + label:before {
        background-image: url($theme-form-sprite-radio-idle);
    }

    input[type=radio]:checked + label:before {
        background-image: url($theme-form-sprite-radio-checked);
    }

    input[type=checkbox] + label:before {
        background-image: url($theme-form-sprite-checkbox-idle);
    }

    input[type=checkbox]:checked + label:before {
        background-image: url($theme-form-sprite-checkbox-checked);
    }

    // chrome bugfix, because chrome doesn't recognize svg :target selector...
    input[type=radio]:checked + label:before,
    input[type=checkbox]:checked + label:before {
        border: 0;
    }

    input[type=radio] + label.inline,
    input[type=checkbox] + label.inline {
        display: inline-block;
    }
}
