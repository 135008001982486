
/**
 * scss/core/nav/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

div.breadcrumbs {
    
    border-top:1px solid #e2dfdc;
    display:none;
    margin-bottom:0;
    background-color: white;
    border-radius: 0px;
    padding:20px;

    ul {
        background-color:transparent;
        @include ul-reset();

        li {

            display:inline-block;
            color:#0B243C;
            font-size:$theme-font-size-s;

            a {
                color:#04296C;
                text-decoration:none;
            }

            a:hover {
                text-decoration:underline;
            }

            &:after {

                content:'/';
                position:relative;
  
                top:0px;
                width:8px;
                height:11px;
                margin-left:8px;
                margin-right:5px;
            }
        }

        li:last-child {
            &:after {
                display:none;
            }
        }
    }
}

@media (min-width: $theme-screen-md) {

    div.breadcrumbs {
        display:block;
    }
}
