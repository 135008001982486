
/**
 * scss/core/components/modal/success.scss
 * ----------------------------------------------------------
 * Modal success component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.modal {
    .modal-success {
        .modal-header {
            h2 {
                color: $theme-modal-color-success;
            }
        }
    }
}