
/**
 * scss/vendor/vendor.scss
 * ----------------------------------------------------------
 * Vendor packages...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 */

/* bootstrap
-------------------------------------------------------------------------------------------- */
@import "bootstrap/scss/functions"; /* from node folder */
@import "bootstrap/bootstrap-theme";
@import 'bootstrap/bootstrap';
@import 'slick-slider/slick-slider';