
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */


body.modal-open {
    -webkit-overflow-scrolling: auto!important;
}

.ul-modal.loading .ul-modal--content {
    &:after {
        width:100%;
        text-align:center;
        position:absolute; 
        display:block;
        top:120px;
        content:'Een moment aub...'
    }
}


.ul-modal {
   
    background: rgba(0, 0, 0, .45);
    position:fixed;
    z-index:1000;
    left:0;
    top:0;
    width:100%;
    height:100%;
    text-align:center;

    .ul-modal--header {

        background:$theme-brand-color-blue-1;
        padding:30px;

        h2 {
            color:white;
            margin:0;
            text-align:left;
        }

        .ul-modal--close {
            position:absolute;
            top:37px;
            right:30px;
        }
    }
    
    .ul-modal--content {
        
        position:relative;
        display:inline-block;
        position:relative;
        background:white;
        width:100%;
        height:100%;
        top:0px;
        max-width:900px;

        .ul-modal--iframe {
            background:white;
            position: relative;
            height:calc(100% - 96px);
            overflow:auto;
            -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
        }
       
        iframe {
            background:white;
            position: absolute; 
            margin:15px;
            border:0;
            left:0;
            width:calc(100% - 45px);
            height:calc(100% - 30px);
        }
    }
}

@media (min-width: $theme-screen-md) {
    .ul-modal {
        padding: 40px;

        .ul-modal--content {
            iframe {
                margin:20px;
                width:calc(100% - 40px);
                height:calc(100% - 40px);
            }
        }
    }
}