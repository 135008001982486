
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.block-warning {

    background:white;
    margin-bottom:-20px;
    padding:30px;
    margin-bottom:10px;

    color:$theme-brand-color-gray-1;

    p {
        font-size:$theme-font-size-l;
    }

    p:last-child {
        margin-bottom:0;
    }
    
    .icon-warning {
        padding-left: 45px;
        min-height:30px;

        &:before {
            background-color:transparent;
            width:30px;
        }
    }
}

.block-warning {

    margin-top:calc( -#{$theme-section-padding-sm} - 15px);

    @media (min-width: $theme-screen-md) {
        margin-top:calc( -#{$theme-section-padding-md} - 30px);
    }

}