
/**
 * scss/core/components/map.scss
 * ----------------------------------------------------------
 * Google Maps component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.google-maps {
    margin-bottom: -20px;
}
.google-maps,
.google-maps #map {
    height: 360px;
}

@media (min-width: $theme-screen-sm) {
    .google-maps,
    .google-maps #map {
        height: 460px;
    }

    /* only needed for full width
    .google-maps {
        margin-left: -10px; // negative bs margin!
        margin-right: -10px; // negative bs margin!
        margin-bottom: -60px; // negative margin!
    }
    */

}

// marker info window content...
.marker-content {
    .field {
        display: block;
        margin-bottom: 1px;
    }
    .field.name {
        margin-bottom: 8px;
    }
    .field.zipcode, .field.city {
        display: inline-block;
    }
    .field.zipcode {
        margin-right: 5px;
    }
    a {
        text-decoration: none;
    }
    a:after {
        content:"\203A";
        display: inline-block;
        margin-left: 3px;
    }
}

