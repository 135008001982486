
/**
 * scss/core/components/accordion
 * ----------------------------------------------------------
 * Carousel component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */


.accordion {
    
    margin-bottom:2em;

    button {
        cursor:pointer;
    }

    /* --- level 1 */
    h4 {
        margin:0;
        button {
            width:100%;
            text-align:left;
            background:white;
            color: $theme-h1-font-color;
            border:0;
            padding:30px 50px 30px 15px;
        }
        
        button {
            position:relative;
            &:after {
                content:'';
                margin-left:12px;
                position:absolute;
                right:25px;
                top:38px;
                content: "";
                display: inline-block;
                width: 9px;
                height: 9px;
                border-right: 2px solid black;
                border-top: 2px solid black;
                transform: rotate(-45deg);
            }   
        }

        button.collapsed {
            &:after {
                top:35px;
                transform: rotate(135deg);
            }     
        }
    }

    .padding-b {
        padding-bottom:30px;
    }

    .padding {
        padding:15px 15px 30px 15px;
    }

    /* --- level 2 */
    
    .collapse, .collapsing {
        h5 {
            width:calc(100% - 40px); // nested width
        }
    }

    h5 {

        border:1px solid $theme-brand-color-gray-2;
        margin:0;

        button {
            width:100%;
            font-size:15px;
            text-align:left;
            background:white;
            color: black;
            border:0;
            padding:10px 45px 10px 15px;
        }

        button {

            background: $theme-brand-color-gray-3;
            position:relative;

            &:after {
                content:'';
                margin-left:12px;
                position:absolute;
                right:20px;
                top:17px;
                content: "";
                display: inline-block;
                width: 9px;
                height: 9px;
                border-right: 2px solid black;
                border-top: 2px solid black;
                transform: rotate(-45deg);
            }   
        }

        button.collapsed {
            background: white;
            &:after {
                top:13px;
                transform: rotate(135deg);
            }     
        }
    }

    h4, h5 {
      margin-top: 10px;    
    }
}   