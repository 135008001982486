
/**
 * scss/core/base/form/input.scss
 * ----------------------------------------------------------
 * File input container...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab.com
 *
 */

label + .tooltip {
    display: none;
}