
/**
 * scss/theme/theme.scss
 * ----------------------------------------------------------
 * Theme variables...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 * Important note:
 * ----------------------------------------------------------
 * This file includes custom styling for each client...
 *
 */


$theme-brand-color-primary:                     #04296C;
$theme-brand-color-secondary:                   #000000;
$theme-brand-color-tertiary:                    #000000;
$theme-brand-color-light:                       #e7e7e7;
$theme-brand-color-dark:                        #666666;

/* main theme colors */
$theme-brand-color-blue-1:                      #04296C;
$theme-brand-color-blue-2:                      #0E6BA8;
$theme-brand-color-blue-3:                      #00AFB2;
$theme-brand-color-blue-4:                      #009092;

$theme-brand-color-orange-1:                    #FBA80A;
$theme-brand-color-orange-2:                    #dd8a05;

$theme-brand-color-gray-1:                      #666666;
$theme-brand-color-gray-2:                      #cccccc;
$theme-brand-color-gray-3:                      #f5f1ee;
$theme-brand-color-gray-4:                      #999999;

$theme-brown-color-1:                           #6F5F50;
$theme-brown-color-2:                           #C9B9A8;
$theme-success-color:                           #0F9E3A;
$theme-error-color:                             #DE3515;

/* not used */


$theme-brand-color-red-1:                       #FF0000;
$theme-brand-color-red-2:                       #FF0000;

$theme-brand-color-green-1:                     #5AA67C;
$theme-brand-color-green-2:                     #5AA67C;
$theme-brand-color-yellow-1:                    #F8C81C;
$theme-brand-color-yellow-2:                    #F8C81C;

$theme-brand-font-family:                       'Open Sans', 'Arial', sans-serif !default;

/* sizes
-------------------------------------------------------------------------------------------- */

$theme-font-size-xs:                            12px !default;
$theme-font-size-s:                             14px !default;
$theme-font-size-m:                             16px !default;
$theme-font-size-l:                             18px !default;
$theme-font-size-xl:                            20px !default;
$theme-font-size-xxl:                           26px !default;
$theme-font-size-xxxl:                          36px !default;


// -----------------------------------------------------------------------------------------
// BASE
// -----------------------------------------------------------------------------------------
/* headings
-------------------------------------------------------------------------------------------- */
$theme-h1-font-family:                          $theme-brand-font-family !default;
$theme-h2-font-family:                          $theme-brand-font-family !default;
$theme-h3-font-family:                          $theme-brand-font-family !default;
$theme-h4-font-family:                          $theme-brand-font-family !default;
$theme-h5-font-family:                          $theme-brand-font-family !default;

// sizes
$theme-h1-font-size:                            36px !default;
$theme-h2-font-size:                            30px !default;
$theme-h3-font-size:                            24px !default;
$theme-h4-font-size:                            20px !default;
$theme-h5-font-size:                            18px !default;

// colors
$theme-h1-font-color:                           $theme-brand-color-primary !default;
$theme-h2-font-color:                           $theme-brand-color-primary !default;
$theme-h3-font-color:                           $theme-brand-color-primary !default;
$theme-h4-font-color:                           $theme-brand-color-primary !default;
$theme-h5-font-color:                           $theme-brand-color-primary !default;

// weights
$theme-h1-font-weight:                            600 !default;
$theme-h2-font-weight:                            600 !default;
$theme-h3-font-weight:                            600 !default;
$theme-h4-font-weight:                            600 !default;
$theme-h5-font-weight:                            600 !default;

/* hyperlink
-------------------------------------------------------------------------------------------- */
$theme-hyperlink-color:                         black !default;
$theme-hyperlink-color-hover:                   black !default;
$theme-hyperlink-color-active:                  black !default;

/* section padding
-------------------------------------------------------------------------------------------- */
$theme-section-padding-sm: 40px;
$theme-section-padding-md: 80px;


/* button [types]
-------------------------------------------------------------------------------------------- */

$theme-button-font-family:                      normal normal 600 $theme-font-size-m 'Open Sans', 'Arial', 'sans-serif'!default;

$theme-btn-base-border-radius: 30px;

$theme-btn-style-1-bg: $theme-brand-color-blue-1, darken($theme-brand-color-blue-1, 5%), darken($theme-brand-color-blue-1, 12%), darken($theme-brand-color-blue-1, 12%);
$theme-btn-style-1-border: $theme-brand-color-blue-1, darken($theme-brand-color-blue-1, 5%), darken($theme-brand-color-blue-1, 12%), darken($theme-brand-color-blue-1, 12%), 0px solid;
$theme-btn-style-1-text: white, white, white, white, $theme-button-font-family;

$theme-btn-style-2-bg: transparent, transparent, transparent, transparent;
$theme-btn-style-2-border: $theme-brand-color-blue-3, darken($theme-brand-color-blue-3, 3%), darken($theme-brand-color-blue-3, 10%), darken($theme-brand-color-blue-3, 10%), 1px solid;
$theme-btn-style-2-text: $theme-brand-color-blue-3, darken($theme-brand-color-blue-3, 3%), darken($theme-brand-color-blue-3, 10%), darken($theme-brand-color-blue-3, 10%), $theme-button-font-family;

$theme-btn-style-3-bg: $theme-brand-color-blue-3, darken($theme-brand-color-blue-3, 3%), darken($theme-brand-color-blue-3, 6%), darken($theme-brand-color-blue-3, 6%);
$theme-btn-style-3-border:$theme-brand-color-blue-3, darken($theme-brand-color-blue-3, 3%), darken($theme-brand-color-blue-3, 6%), darken($theme-brand-color-blue-3, 6%), 1px solid;
$theme-btn-style-3-text: white, white, white, white, $theme-button-font-family;

$theme-btn-style-4-bg: transparent, $theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3;
$theme-btn-style-4-border:$theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3, 1px solid;
$theme-btn-style-4-text: $theme-brand-color-blue-3, white, white, white, $theme-button-font-family;

$theme-btn-style-5-bg: $theme-brand-color-blue-1, $theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3;
$theme-btn-style-5-border:$theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3, 0px solid;
$theme-btn-style-5-text: white, white, white, white, $theme-button-font-family;

$theme-btn-style-6-bg: $theme-brand-color-blue-1, $theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3;
$theme-btn-style-6-border:$theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3, $theme-brand-color-blue-3, 0px solid;
$theme-btn-style-6-text: white, white, white, white, $theme-button-font-family;

$theme-btn-style-7-bg: $theme-brand-color-orange-1, darken($theme-brand-color-orange-1, 5%), darken($theme-brand-color-orange-1, 12%), darken($theme-brand-color-orange-1, 12%);
$theme-btn-style-7-border: $theme-brand-color-orange-1, darken($theme-brand-color-orange-1, 5%), darken($theme-brand-color-orange-1, 12%), darken($theme-brand-color-orange-1, 12%), 0px solid;
$theme-btn-style-7-text: white, white, white, white, $theme-button-font-family;

$theme-btn-style-8-bg: transparent, transparent, transparent, transparent;
$theme-btn-style-8-border: $theme-brand-color-orange-1, darken($theme-brand-color-orange-1, 3%), darken($theme-brand-color-orange-1, 10%), darken($theme-brand-color-orange-1, 10%), 1px solid;
$theme-btn-style-8-text: $theme-brand-color-orange-1, darken($theme-brand-color-orange-1, 3%), darken($theme-brand-color-orange-1, 10%), darken($theme-brand-color-orange-1, 10%), $theme-button-font-family;

