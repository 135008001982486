
/**
 * scss/core/base/form/field-validation-error.scss
 * ----------------------------------------------------------
 * Field validation error...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab.com
 *
 */

//#main .form-wrapper span.field-validation-error,
.field-validation-error {
    display: block;
    color: $theme-form-color-error;
    font-size: $theme-form-error-font-size;
    margin: 5px 0 0 0;
}