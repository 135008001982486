
/**
 * scss/core/base/base.scss
 * ----------------------------------------------------------
 * Application base styles and browser resets...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.form-row {
    > label {
        display: block;
        font-weight: $theme-form-label-font-weight;
    }
}
