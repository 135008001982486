
/**
 * scss/core/components/youtube-video
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

div.list-vacancies-container {
    position:relative;
}

div.list-vacancies-no-result {
    border-top:1px solid $theme-brand-color-blue-1;
    padding:30px 0;

    div.box {
        background:$theme-brand-color-light;
        padding:30px;

        .link-telephone {
            font-weight:bold;
        }
    }
}

@media (min-width: $theme-screen-md) {
    div.list-vacancies-container {
        h3 {
            position:absolute;
            bottom:100%;
        }
    }
}

ul.list-vacancies {

    @include ul-reset();

    li:first-child {
        border-top:1px solid $theme-brand-color-blue-1;
    }

    li {
        border-bottom:1px solid $theme-brand-color-blue-1;
        padding:30px 0;

        h2 {
            margin-bottom:0.35em;
        }

        p.metadata {
            color:$theme-brand-color-blue-3;
            margin-bottom:0.8em;
        }

        p {
            margin-bottom:1.3em;
        }

        p:last-child {
            margin:0;
        }
    }
}
