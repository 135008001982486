
/**
 * scss/core/base/form/textarea.scss
 * ----------------------------------------------------------
 * Textarea styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.textarea-container {
    background: white; // todo: should be a variable!
    border: $theme-form-element-border solid $theme-form-color-default;

    textarea {
        background: transparent;
        border: 0;
        display: block;
        padding: $theme-form-element-padding;
        font-size: $theme-body-font-size;
        width: 100%;
    }
}

.textarea-container.input-error {
    border-color: $theme-form-color-error;
}