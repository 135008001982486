
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

div.block-feedback {

    margin-top:2.5em;
    width: calc(100% - 25px);
    border:1px solid $theme-brand-color-gray-2;
    padding:10px 20px;

    h5 {
        border:0;
        margin-bottom:1.2em;
        color:black;
        font-size: 15px;
    }

    .icon-thumb-up {
        margin-right:10px;
    }

    .icon-thumb-up,
    .icon-thumb-down {
    
        display:inline-block;
        padding-left:40px;
        width:40px;
        overflow:hidden;
        margin-right:15px;        

        &:before {
            content:'';
            position:absolute;
            left:0; top:0;
            width:40px;
            height:40px;
            background-color:$theme-brown-color-1;
            border-radius: 6px;
        }
    }
}
