
/**
 * scss/core/base/form/select.scss
 * ----------------------------------------------------------
 * Select styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.select-container {

    background: white;
    border: $theme-form-element-border solid $theme-form-color-default;
    border-radius: $theme-form-element-radius;
    overflow: hidden;
    position: relative;

    // hide default select element on ie 10+
    select::-ms-expand {
        display: none;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        background: transparent no-repeat -100px 0; /* safari fix */
        border: 0;
        padding: $theme-form-element-padding;
        width: 100%;
    }

    &:after {
        background: url($theme-form-sprite-select) center center no-repeat;
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        right: 8px;
        top: 0;
        height: 100%;
    }
}