
/**
 * scss/core/components/nav/pills.scss
 * ----------------------------------------------------------
 * Bootstrap Nav pills adjustments...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.nav.nav-pills {
    li {
        a {
            background: white;
        }
        a.router-link-active {
            background: $theme-hyperlink-color;
            color: white;
        }
    }
    li + li {
        margin-left: 10px;
    }
}