/**
 * scss/core/components/nav/main-secondary.scss
 * ----------------------------------------------------------
 * Navigation main secondary...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

// -----------------------------------------------------------------------------------------
// styles
// -----------------------------------------------------------------------------------------

div#nav-main-secondary {
    background: $theme-nav-main-background;
    height: $theme-nav-main-height;
    position: relative;

    button {
        @include button-base(0, false, 0);
        @include vertical-align();
        display: block;
        position: absolute;
        right: 5px;
        width: $theme-nav-main-toggle-size;
        height: $theme-nav-main-toggle-size;
    }

    .button-toggle {
        background: url($theme-nav-main-toggle-icon-sprite) 0 -#{$theme-nav-main-toggle-size} no-repeat;
        width: $theme-nav-main-toggle-size;
        height: $theme-nav-main-toggle-size;
    }

    .button-toggle.collapsed {
        background-position: 0 0;
    }

    nav {
        background: $theme-nav-main-background;
        position: absolute;
        top: $theme-nav-main-height;
        left: 0;
        right: 0;
        z-index: 1000;

        ul {
            @include ul-reset();

            li a {
                display: block;
                padding: 0 $theme-nav-main-link-padding $theme-nav-main-link-padding $theme-nav-main-link-padding;
                text-align: center;
            }
            li:first-child a {
                padding-top: $theme-nav-main-link-padding;
            }
            li a, li a:hover {
                color: $theme-nav-main-link-color;
            }
        }
    }
}

/**
 *
 * Media query
 * ----------------------------------------------------------
 * Handles responsive queries for Medium devices
 *
 * @width       780pixels
 * @container   750pixels
 *
 */

@media (min-width: $theme-screen-sm) {
    div#nav-main-secondary {
        nav {
            @include vertical-align();
            display: block;
            right: 0;
            left: auto;

            ul {
                @include clearfix();

                li {
                    float: left;
                }
                li a, li:first-child a {
                    padding: 0 $theme-nav-main-link-padding;
                }
            }
        }

        // overrule when bs collapse has been toggled before...
        nav[style] {
            height: auto !important;
        }

        .button-toggle {
            display: none;
        }
    }
}