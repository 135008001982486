
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */


header.header-error .image {
    background-image:url(/Assets/img/headers/header-error.jpg);
    background-size: auto 350px !important;
    background-repeat: no-repeat;
    background-position: 15% center;
    background-color: #dbd7d3;
}