
/**
 * scss/core/layout/grid-sections.scss
 * ----------------------------------------------------------
 * These classes can be applied to the rows of a grid editor
 * page in the CMS...
 *
 * @type       Sass
 * @author     PJ Arts  <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 * Options in the grid editor:
 * ----------------------------------------------------------
 *  [class]
 *      .section-transparent
 *      .section-white
 *      .section-default
 *      .section-primary
 *      .section-secondary
 *
 *  [data-row-offset]
        0: Standaard uitlijning
 *      1: 1 Kolom inspringen 
 *      2: 2 Kolommen inspringen
        3: Fluid
 *
 *  [data-section-min-height]
 *      auto
 *      medium
 *      large
 *
 *  [data-row-align]
 *      left
 *      center
 *      right
 *
 */

/* background
------------------------------------------------------------ */
.grid-section > div[style^="background-image:"] {
    background-size: cover;
    background-position: center;
}

/* section padding
------------------------------------------------------------ */
.grid-section > div,
.section-transparent,
.section-light,
.section-dark,
.section-white,
.section-gray,
.section-gray-overlap,
.section-default,
.section-primary,
.section-secondary {
    padding-top: $theme-section-padding-sm;
    padding-bottom:  $theme-section-padding-sm;
}

@media (min-width: $theme-screen-md) {
    .grid-section > div,
    .section-transparent,
    .section-light,
    .section-dark,
    .section-white,
    .section-gray,
    .section-gray-overlap,
    .section-default,
    .section-primary,
    .section-secondary {
        padding-top: $theme-section-padding-md;
        padding-bottom:  $theme-section-padding-md;
    }
}

/* section types & colors
------------------------------------------------------------ */
.section-transparent {
    background-color: transparent;
}

header .section-dark, 
header .section-dark .column {
    p, span, h1, h2, h3, h4, h5 {
        color:white;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    }
}

.section-white {
    background-color: white;
}

.section-default {
    background-color: $theme-section-color-default;
}

.section-primary {
    background-color: $theme-section-color-primary;
}

.section-secondary {
    background-color: $theme-section-color-secondary;
}

// fluid
[data-row-offset="3"] .container {
    width: auto;

    @media (min-width: $theme-screen-sm), (min-width: $theme-screen-md), (min-width: $theme-screen-lg) {
        width: auto;
    }
}

/* ------------------------------------------------------------ */
// offset row one column (from $screen-sm and up)
@media (min-width: $theme-screen-sm) {
    [data-row-offset="1"] > .container > .row {
        padding: 0 8.33333%;
    }

    [data-row-offset="2"] > .container > .row {
        padding: 0 16.66666%;
    }
}

/* ------------------------------------------------------------ */
// offset row one column (from $screen-sm and up)
@media (min-width: $theme-screen-lg) {
    [data-row-lg-offset="1"] > .container > .row {
        padding: 0 8.33333%;
    }
}

/* row content alignments
------------------------------------------------------------ */
[data-row-align="left"] {
    text-align: left;
}

[data-row-align="center"] {
    text-align: center;
}

[data-row-align="right"] {
    text-align: right;
}

/* column content alignments
------------------------------------------------------------ */
[data-col-align="left"] {
    text-align: left;
}

[data-col-align="center"] {
    text-align: center;
}

[data-col-align="right"] {
    text-align: right;
}
/* section marges
------------------------------------------------------------ */
[data-row-marges="none"] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}   
 
[data-row-marges="small"] {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}    

[data-row-marges="top"] {
    padding-bottom: 0 !important;
}

[data-row-marges="bottom"] {
    padding-top: 0 !important;
}
/* border
------------------------------------------------------------ */
[data-row-borders="yes"] {
    border-top: $theme-section-border;
    border-bottom: $theme-section-border;
}
/* section min height
------------------------------------------------------------ */
[data-section-min-height="medium"] {
    min-height: $theme-section-min-height-medium;
}

[data-section-min-height="large"] {
    min-height: $theme-section-min-height-large;
}

@media (min-width: $theme-screen-md) {
    [data-section-min-height="medium"] {
        min-height: $theme-section-min-height-md-medium;
    }

    [data-section-min-height="large"] {
        min-height: $theme-section-min-height-md-large;
    }
}
/* remove padding between rows of the same type
------------------------------------------------------------ */
.section-transparent + .section-transparent,
.section-white + .section-white,
.section-light + .section-light,
.section-dark + .section-dark,
.section-gray + .section-gray,
.section-gray-overlap + .section-gray-overlap,
.section-default + .section-default,
.section-primary + .section-primary,
.section-secondary + .section-secondary {
    &:not([data-row-separator="true"]) {
        padding-top: 0;
    }
}


[data-background-image] {
    background-size: cover;
    background-position: center center;
}
