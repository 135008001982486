
/**
 * scss/core/base/form.scss
 * ----------------------------------------------------------
 * Form styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* reset
------------------------------------------------------------ */
input, input:focus,
textarea, textarea:focus,
select, select:focus {
    outline: 0;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

.radio, .checkbox {
    margin: 0;
}

/* form defaults
------------------------------------------------------------ */
.form-wrapper {

    background: $theme-form-wrapper-background;
    padding: $theme-form-wrapper-padding;
    margin: $theme-form-wrapper-margin;
    text-align: left;

    label {
        font-weight: normal;
    }

    .form-row {
        padding-bottom: $theme-form-row-margin-bottom;
        position: relative;
    }

    .row .form-row {
        padding-bottom: 0;
    }

    .row.form-row {
        .column + .column {
            padding-top: $theme-form-row-margin-bottom;
        }
    }

    .form-row.form-row-last {
        padding-top: $theme-form-row-margin-bottom;
        padding-bottom: 0;
    }

    .form-group:last-child {
        margin-bottom: 0;
    }
}

@media (min-width: $theme-screen-sm) {
    .form-wrapper {
        .row.form-row {
            .column + .column {
                padding-top: 0;
            }
        }
    }
}

/* import
------------------------------------------------------------ */
@import 'form/field-validation-error';
@import 'form/fieldset';
@import 'form/indicates-required';
@import 'form/input';
@import 'form/input-file';
@import 'form/input-search';
@import 'form/label';
@import 'form/legend';
@import 'form/option';
@import 'form/select';
@import 'form/text';
@import 'form/textarea';
@import 'form/tooltip';