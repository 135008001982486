
/**
 * scss/site/components/test-component
 * ----------------------------------------------------------
 * Example Vue Component with placeholder rendering with states
 * for loading and Umbraco
 *
 * @type       Sass
 * @copyright  2019 Uselab.com
 *
 */


.test-component  {

    /* component placeholder default styles only shown when loading
    ------------------------------------------------------------ */
    .vue__placeholder {
        border: 1px solid $theme-brand-color-gray-2;
        width: 100%;
        width: 300px;
        height: 200px;    
        padding: 50px;
        text-align: center;
    }
}

@media (min-width: $theme-screen-sm) {

}

/* vue component placeholder inside the Umbraco editor
------------------------------------------------------------ */
.ng-scope {
    .test-component  {
        .vue__component + .vue__placeholder {
            &:after {
                content:'Test component';
            }
        }
    }
}
