
/**
 * scss/core/components/block-image-text.scss
 * ----------------------------------------------------------
 * Block with an image and text and optional footer
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.block-text-on-image {
    display:block;
    margin-bottom: 20px;

    div.footer,
    div.text {
        padding: $theme-block-padding-sm;
        position:relative;
        color:white;

        h1, h2, h3, h4, p, span, blockquote {

            /* needed for when the complete block is a href */
            display:inline-block;
            width:100%;

            color:white;
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
        }
    }

    div.footer {
        h1, h2, h3, h4, h5 {
            margin-bottom:0;
        }
    }

    div.text + div.footer {
        padding-top:0;
    }

    p:last-child {
        margin-bottom:0;
    }
}

.block-text-on-image.secondary {
    div.footer,
    div.text {
        color: $theme-body-font-color;

        h1, h2, h3, h4, p, span, blockquote {
            color: $theme-body-font-color;
            text-shadow: 0 0 0;
        }
    }
}

@media (min-width: $theme-screen-md) {

    /* double padding option set from Umbraco docType */
    .block-text-on-image[data-double-padding="True"] {
        $double-padding: $theme-block-padding-md * 2;
        div.text,
        div.footer {
            padding: $double-padding;
        }
    }

    .block-text-on-image {
        div.text,
        div.footer {
            padding: $theme-block-padding-md;
        }
    }
}

[data-section-flex="true"] {
    .row {
        .column {
            > div {
                width:100%;
                display:flex;
            }
            > div.image {
                display: block;
            }
            .block-text-on-image {
                display:flex;
                flex-direction: column;
                width:100%;
                overflow:hidden;

                .footer {
                    position:relative;
                    /* both are needed for IE11 */
                    margin-top:auto;
                    justify-content: flex-end;
                }
            }
        }
    }
}