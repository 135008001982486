
/**
 * core/icon/base
 * ----------------------------------------------------------
 * Base icon definitions...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2015 Uselab.com
 *
 */

.icon-40 {
  background-size:40px 40px;
}
.nav-left {
  .nav-list__item {

    &.icon-assets a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-assets.svg);
    }

    &.active {
      &.icon-assets a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-assets-active.svg);
      }
    }

    &.icon-dashboard a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-dashboard.svg);
    }

    &.active {
      &.icon-dashboard a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-dashboard-active.svg);
      }
    }

    &.icon-data-analyse a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-data-analyse.svg);
    }

    &.active {
      &.icon-data-analyse a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-data-analyse-active.svg);
      }
    }

    &.icon-home a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-home.svg);
    }

    &.active {
      &.icon-home a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-home-active.svg);
      }
    }

    &.icon-hulp a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-hulp.svg);
    }

    &.active {
      &.icon-hulp a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-hulp-active.svg);
      }
    }

    &.icon-notify a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-notify.svg);
    }

    &.active {
      &.icon-notify a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-notify-active.svg);
      }
    }

    &.icon-organisatie a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-organisatie.svg);
    }

    &.active {
      &.icon-organisatie a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-organisatie-active.svg);
      }
    }

    &.icon-account a {
      background-image: url(/Assets/img/myfudura/icons/menu/icon-account.svg);
    }

    &.active {
      &.icon-account a {
        background-image: url(/Assets/img/myfudura/icons/menu/icon-account-active.svg);
      }
    }
  }
}