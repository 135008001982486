
/**
 * scss/core/layout/footer.scss
 * ----------------------------------------------------------
 * Footer layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

#footer {

    
    padding:25px 0;
    background:$theme-brand-color-gray-3;
    border-bottom:1px solid $theme-brown-color-2;

    .column {
        // padding-bottom:30px;
    }
    
    h5 {
        margin-bottom:1em;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position:relative;
            padding-left: 17px; 
            padding-bottom: 5px;
            color: $theme-brand-color-blue-1;

            a {
                color: $theme-brand-color-blue-1;
                text-decoration:none;
            }

            a:hover {
                text-decoration:underline;
            }
        }

        li::before {
            content: "";
            position:absolute;
            left:0;
            top:9px;
            display:block;
            width:6px;
            height:6px;
            border-radius:3px;
            background: $theme-brand-color-blue-3;
        }
    }
}


.terms-and-conditions {
    
    padding:10px 0;
    .logo {
        text-align:center;
        padding:20px 10px;
        img {
            height:30px;
        }
    }

    ul {
        text-align:center;
        margin:0; padding:0;
        list-style:none;
        li {
            display:inline-block;
            padding:4px 10px;

            a {
                color: $theme-brand-color-blue-1;
                text-decoration:none;
            }

            a:hover {
                text-decoration:underline;
            }
        }
    }

    .social {
        text-align:center;
        padding:25px 0 15px 0;

        .icon-svg {
            margin-left:7px;
            margin-right:7px;
        }
    }


}

@media (min-width: $theme-screen-sm) {

    #footer {
        padding:80px 0;
        .column {
            padding-bottom:0px;
        }
    }

    .terms-and-conditions {
    
        padding:30px 0;

        .logo {
            padding:0 15px 0 0;
            float:left;
            img {
                height:30px;
            }
        }

        ul {
            float:left;
            margin:0 0 0 10px; padding:0;
            list-style:none;
            li {
                display:inline-block;
                padding:4px 10px;
            }
        }

        .social {
            float:right;
            padding:0;
            position:relative;
            top:8px;
            .icon-svg {
                margin-left:10px;
                margin-right:0px;
            }
        }
    }
}