
/**
 * scss/core/components/youtube-video
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.youtube-player {

    &:after {
        content:'';
        width:64px;
        height:48px;
        background:url(/assets/img/b-play.svg);
        background-size:64px 48px;
        left:calc(50% - 32px);
        top:calc(50% - 24px);
        position:absolute;
    }
}

.youtube-player.visible {
    &:after {
        display:none;
    }
    iframe {
        opacity:1;
        top:0; left:0;
    }
}