
/**
 * scss/core/base/form.scss
 * ----------------------------------------------------------
 * Form styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

// default form styles
.form-wrapper {
    .form-row {
        @include clearfix();

        > label,
        .indicates-required,
        .option-container {
            margin-top: 9px;
        }

        input[type=radio] + label,
        input[type=checkbox] + label {
            padding-top: 0;
            padding-left: 33px;
        }

        .field-validation-error {
            color: $theme-body-font-color;
        }
    }

    div.input-container.input-file .custom-file-upload:after {
        border: 1px solid $theme-brand-color-primary;
        border-radius: 0;
        color: $theme-brand-color-primary;
        right: 3px;
        padding: 8px 12px;
        font-weight: bold;
    }

    .fieldset-footer {
        padding-bottom: 1.5em;

        .btn.btn-primary {
            @include button-background( nth(white, $theme-btn-state-idle), nth($theme-btn-style-2-bg, $theme-btn-state-hover), nth($theme-btn-style-2-bg, $theme-btn-state-focus), nth($theme-btn-style-2-bg, $theme-btn-state-active) );
            @include button-border( nth($theme-btn-style-2-border, $theme-btn-state-idle), nth($theme-btn-style-2-border, $theme-btn-state-hover), nth($theme-btn-style-2-border, $theme-btn-state-focus), nth($theme-btn-style-2-border, $theme-btn-state-active), nth($theme-btn-style-2-border, 5) );
            @include button-text( nth($theme-btn-style-2-text, $theme-btn-state-idle), nth($theme-btn-style-2-text, $theme-btn-state-hover), nth($theme-btn-style-2-text, $theme-btn-state-focus), nth($theme-btn-style-2-text, $theme-btn-state-active), nth($theme-btn-style-2-text, 5) );
            padding: 10px 20px;
            font-weight: bold;
        }
    }

    .text-container {
        text-align: center;

        @media (min-width: $theme-screen-md) {
            padding: 0 100px;
        }
    }
}

h3 + .form-wrapper {
    margin-top: 20px;
}

// default form styles (min-width)
@media (min-width: $theme-screen-sm) {
    .form-wrapper {
        .form-row {
            > label {
                display: block;
                width: 40%;
                float: left;
                text-align: right;
                position: relative;
                padding-right: 20px; 

                .indicates-required {
                    position: absolute;
                    right: 0;
                    margin-top: 8px;
                }
            }
        }

        div.option-container,
        div.input-container,
        div.textarea-container,
        div#recaptcha_widget_div {
            display: block;
            width: calc(60% - 10px);
            margin-left: 10px;
            float: right;
        }

        .fieldset-footer,
        .field-validation-error {
            margin-left: calc(40% + 10px);
        }

        .field-validation-error {
            clear: both;
            margin-top: 0;
            padding-top: 5px;
            color: $theme-body-font-color;
        }
    }
}

// form solliciteren
#umbraco_form_d7182eb8667b4272b9e616df5c833007 {
    @media (max-width: $theme-screen-sm) {
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: transparent;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: transparent;
        }

        :-ms-input-placeholder { /* IE 10+ */
            color: transparent;
        }

        :-moz-placeholder { /* Firefox 18- */
            color: transparent;
        } 
    }

    @media (min-width: $theme-screen-sm) {
        .form-row.aanhef label span.label-value,
        .form-row.tussenvoegsel label span.label-value,
        .form-row.achternaam label span.label-value {
            display: none;
        }
    }

    @media (min-width: $theme-screen-sm) {
        form {
            // fixed centering of form in wrapper
            margin-right: 15%;
        }

        .form-row.gebruikmijnlinkedinprofiel {
            padding-bottom: 35px;
        }
    }
}

// input search fixes
.input-container.input-search {
    border: 1px solid $theme-brand-color-gray-2;
    height: 44px;

    form {
        display: block;
    }

    input[type="submit"] {
        height: 44px;
        top: 0;
        right: 0;
        margin-right: -1px;
        margin-top: -1px;
    }

    .btn-style-5 {
        border-radius: 0;
    }
}