
/**
 * scss/shared.scss
 * ----------------------------------------------------------
 * Site library...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* font should always load as first thing in CSS 
------------------------------------------------------------ */
@import 'theme/fonts/_opensans.scss';

/* shared 
------------------------------------------------------------ */
@import "vendor/vendor";
@import "theme/theme";  // custom theme!
@import "core/core";   // core, do not change!


/* base
------------------------------------------------------------ */
@import 'base/base';
@import 'base/btn';
@import 'base/heading';
@import 'base/form';
@import 'base/hyperlink';
@import 'base/icon';
@import 'base/heading';

/* shared components
------------------------------------------------------------ */
@import 'components/block';
@import 'components/nav';
@import 'components/carousel';
@import 'components/list-vacancies';
@import 'components/meta-data';
@import 'components/share';
@import 'components/youtube-player';
@import 'components/nav/paging';
@import 'components/accordion';
@import 'components/tooltip';
@import 'components/scroll-container';
@import 'components/modal';
@import 'components/cookie-bar';
@import "components/cookie-statement";
@import "components/vue/vue";

/* shared modules
------------------------------------------------------------ */
@import 'modules/umbraco-forms';

/* shared layout
------------------------------------------------------------ */
@import 'layout/header';
@import 'layout/grid-section';
@import 'layout/footer';