
/**
 * scss/myfudura/layout/login.scss
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts  <pj.arts@uselab.com>
 * @copyright  2020 Uselab.com
 *
 */

.column-login {
  width: 100%;
  padding: 0 #{$grid-gutter-width / 2};
}

@media (min-width: $theme-screen-sm) {
  .column-login {
    width: 420px;
  }
}