/**
 * myfudura left navigation
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

$nav-left-reduced-width: 70px;
$nav-left-expanded-width: 247px;
$nav-left-item-height: 70px;
$nav-left-icon-size: 30px;

/* menu toggle button
  ------------------------------------------------------------ */
button.nav-left__toggle-mobile,
button.nav-left__toggle-desktop {

  &:before {
    content: '';
    position: relative;
    background-image: url(/Assets/img/myfudura/icons/icon-sprite-hamburger-close.svg);
    background-repeat: no-repeat;
    background-position: top center;
  }

  border: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: transparent;
  overflow: hidden;
  z-index: 3;
  position: relative;
}

button.nav-left__toggle-mobile {
  background-color: transparent;
  display: block;
  background-size: 30px 60px;
  width: 60px;
  height: 61px;

  &:before {
    top: 12px;
    left: 15px;
    background-size: 30px 60px;
    display: block;
    width: 30px;
    height: 30px;
  }
}

button.nav-left__toggle-desktop {
  transition: background-color 0.05s ease-in-out, box-shadow 0.2s ease-in-out;
  background-color: transparent;
  display: none;
  width: 70px;
  height: 70px;

  &:before {
    top: 15px;
    left: 15px;
    background-size: 40px 80px;
    display: block;
    width: 40px;
    height: 40px;
  }
}

.nav-left {
  z-index: 2;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  div.box-shadow {
    background: white;
    transition: width 0.2s ease-in-out;
    width: 0; // $nav-left-reduced-width; -> desktop
    overflow: hidden;
    box-shadow: 0 2px 10px 1px rgba(0,0,0,0.1);
    left: 0;
    top: 60px;
    bottom: 0;
    position: absolute;
  }

  .nav-list {
    @include ul-reset();
    margin-bottom: 15px;
    font-size: 15px;

    &__item {
      position: relative;
      z-index: 1;
      transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, padding 0.2s ease-in-out;
      border-right: 2px solid white;

      &.active,
      &:hover {
        background: white;
        z-index: 2;
        box-shadow: -5px 4px 4px rgba(0,0,0,0.0), -5px -3px 3px rgba(0,0,0,0.0);
        border-color: $theme-brand-color-orange-2;
      }

      a {
        transition: all 0.2s ease-in-out;
        color: transparent;
        background: url(/Assets/img/icons/icon-globe.svg) no-repeat 15px center;
        white-space: nowrap;
        display: block;
        line-height: $nav-left-item-height;
        padding: 0 30px 0 65px;
        position: relative;
        display: block;
        text-decoration: none;
      }
    }
  }

  #btn-back-to-website {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: block;
    white-space: nowrap;
    line-height: 70px;
    padding: 0 30px;
    margin-top: 9px;
    margin-bottom: 18px;
  }

  .wrapper__btn-logout {
    padding-top: 30px;
    margin: 0 30px;
    width: calc(#{$nav-left-expanded-width - 60px});
    transition: border-color 0.2s ease-in-out;
    border-top: 1px solid transparent;
    text-align: center;

    #btn-logout {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      min-width: 0;
    }
  }
}

/* menu expanded state
------------------------------------------------------------ */
.nav-left.expanded {

  div.box-shadow {
    width: $nav-left-expanded-width;
  }

  button.nav-left__toggle-mobile,
  button.nav-left__toggle-desktop {
    &:before {
      background-position: bottom center;
    }
  }

  button.nav-left__toggle-mobile {
    background-color: white;
    box-shadow: 0 -10px 6px 1px rgba(0,0,0,0.1);
  }

  .nav-list {
    &__item {

      padding-left: 10px;

      &.active,
      &:hover {
        box-shadow: -5px 3px 3px rgba(0,0,0,0.1), -5px -3px 3px rgba(0,0,0,0.1);

        a {
          color: $theme-brand-color-blue-4;
        }
      }

      a {
        color: $theme-brand-color-blue-1;
      }
    }
  }

  #btn-back-to-website {
    opacity: 1;
  }

  .wrapper__btn-logout {
    border-top: 1px solid $theme-brand-color-primary;

    #btn-logout {
      opacity: 1;
    }
  }
}

@media (min-width: $theme-screen-md) {
  .nav-left {
    div.box-shadow {
      top: 0;
      width: $nav-left-reduced-width;
    }

    #btn-back-to-website {
      margin-top: 1px;
      margin-bottom: 28px;
    }

    button.nav-left__toggle-mobile {
      display: none;
    }

    button.nav-left__toggle-desktop {
      display: block;
    }
  }
}
