
/**
 * scss/core/components/block/block-default.scss
 * ----------------------------------------------------------
 * Block default component, this block is used as a 
 *  wrapper for content imported from Otys...
 *
 * @type       Sass
 * @author     K van den Broek <k.vandenbroek@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

.block-default {
    padding-bottom: 2.5em;

    h2 {
        //margin-bottom: 0.25em;
    }

    ul {
        @include ul-default(false, 4px, $theme-ul-primary, '', 10px);

        li {
            padding-left: 20px;
        }

        li:before {
            left: 1px;
        }
    }

    p, ul {
        margin-bottom: 1.5em;
    }

    p:last-child,
    ul:last-child {
        margin: 0;
    }
    // bel mij terug form
    #umbraco_form_4c4d3857267949639c57f731e45d2afb {
        .caption,
        .form-row > label {
            display: none;
        }

        div.option-container,
        div.input-container,
        div.textarea-container,
        div#recaptcha_widget_div {
            width: auto;
            float: none;
            margin-left: 0;
        }

        .input-container:not(.input-error) {
            border-color: $theme-brand-color-gray-1;
        }

        .field-validation-error {
            margin-left: 0;
        }

        .fieldset-footer {
            margin: 0;
            padding: 0;

            .btn-primary { 
                @include button-background( nth($theme-btn-style-3-bg, $theme-btn-state-idle), nth($theme-btn-style-3-bg, $theme-btn-state-hover), nth($theme-btn-style-3-bg, $theme-btn-state-focus), nth($theme-btn-style-3-bg, $theme-btn-state-active) );
                @include button-border( nth($theme-btn-style-3-border, $theme-btn-state-idle), nth($theme-btn-style-3-border, $theme-btn-state-hover), nth($theme-btn-style-3-border, $theme-btn-state-focus), nth($theme-btn-style-3-border, $theme-btn-state-active), nth($theme-btn-style-3-border, 5) );
                @include button-text( nth($theme-btn-style-3-text, $theme-btn-state-idle), nth($theme-btn-style-3-text, $theme-btn-state-hover), nth($theme-btn-style-3-text, $theme-btn-state-focus), nth($theme-btn-style-3-text, $theme-btn-state-active), nth($theme-btn-style-3-text, 5) );
            }
        }
    }
}

.block-default:last-child {
    padding-bottom: 0;
}


@media (min-width: $theme-screen-md) {
    // col-md-8 specific styles...
    .column.col-md-8 {
        .block-default {
            margin-right: 10%;
        }
    }
}