
/**
 * scss/core/components/alert/success.scss
 * ----------------------------------------------------------
 * Alert success component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.alert.alert-success {
    background: $theme-alert-color-success;
    border-color: $theme-alert-color-success;
    color: white;
}