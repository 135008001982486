
/**
 * scss/site/base/heading.scss
 * ----------------------------------------------------------
 * Site specific styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

 h1, h2, h3, h4, h5 {
    
    line-height:1.4em;
    font-weight:300;

    a {
        font-weight:300;
        text-decoration:none;
    }
    a:hover {
       text-decoration:underline; 
    }
 }
