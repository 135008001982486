
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

@import "block/block-image-text";
@import "block/block-default";
@import "block/block-text-on-image";
@import "block/block-quote";
@import "block/block-employees";
@import "block/block-warning";
@import "block/block-product";
@import "block/block-news";
@import "block/block-feedback";
