
/**
 * scss/core/base/form/text.scss
 * ----------------------------------------------------------
 * File input container...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab.com
 *
 */

.text-container {
    margin-top: 10px;

    h2 {
        margin-bottom: 0.5em;
    }
    h2 + p {
        margin-top: 0.75em;
    }
}