
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

a[data-toggle="dropdown"].arrow {
  position: relative;
  &:after {
    margin-left: 12px;
    position: absolute;
    right: 0;
    top: 8px;
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    position: relative;
    top: -4px;
    border-right: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(135deg);
  }
}

body.search-active .page-wrapper {
  padding-top: 140px;
}

.page-wrapper {
  transition: all 0.35s ease;
  padding-top: 60px;
  position: relative;

  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    padding-top: 70px;
  }
}

/* icons in the top bar  (specific for mobile, in dropdown menu, we show only the language selector) */
div.ul-paged-menu > ul > li.right-menu {
  padding: 40px 20px 0 20px;
  /* line over language on mobile*/
  vertical-align: top;

  &:before {
    content: '';
    display: block;
    background: $theme-brand-color-primary;
    height: 1px;
    margin-bottom: 20px;
  }

  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    padding: 1px 10px 0 0;
    text-align: right;

    &:before {
      display: none;
    }
  }

  .icon-sprite-40px.icon-menu {

    float:left;
    vertical-align:top;
    position: relative;
    top: 13px;
    margin-left: 15px;
  }

  .right-menu__item--lang {
    padding-top: 21px;
    a {
      padding-left: 0;
    }
  }

  .right-menu__item--user,
  .right-menu__item--search {
    display: none;

    a {
      text-decoration: none;
    }


    @media (min-width: map-get($grid-breakpoints, "xxl")) {
      display: inline-block;
    }
  }
}

/* icons in the top bar */
.ul-paged-menu {

  .right-menu {
    white-space: nowrap;

    &__item {
      display: inline-block;
    }

    a {
      display: inline-block;
    }

    .right-menu__item--lang {
      .dropdown-menu {
        margin-right: -15px;
        margin-top: 20px;
      }
    }

    .dropdown-menu {

      box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
      margin-top: 29px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 10px;
        width: 65px;
        top: -4px;
        right: 0;
        background: white;
      }
    }

    .dropdown-menu {
      &--login {
        padding: 40px 30px 30px 30px;
        background-color: white;
        margin-right: -10px;

        a.btn-register {
          margin-top: 10px;
          font-weight: normal;
          text-decoration: underline;
          padding: 0;
          font-size: $theme-font-size-s;
          color: $theme-brand-color-primary;
        }
      }

      &--language {
        padding: 10px 40px 20px 40px;
        background-color: white;
        text-align: right;
        min-width: 50px;

        &:before {
          width: 100%;
        }

        a {
          margin-top: 10px;
          font-weight: normal;
          padding: 0;

          &.active {
            display: none;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.ul-paged-menu {

  &.active {
    .mobile-bar {
      .icon-svg.icon-hamburger {
        background-color: white;
        box-shadow: 0 -10px 6px 1px rgba(0,0,0,0.1);
      }
    }
  }


  .mobile-bar {
    background: white;
    border-bottom: 1px solid #C9B9A8;
    height: 60px;
    position: relative;
    padding-left: 0;
    padding-right: 10px;

    @media (min-width: map-get($grid-breakpoints, "xxl")) {
      display: none !important;
    }

    .icon-hamburger {
      transition: background-color 0.05s ease-in-out, box-shadow 0.2s ease-in-out;
      width: 60px;
      height: 60px;
      background-position: center center;
    }

    .right-menu {
      margin-top: 6px;
    }

    .icon-sprite-40px.icon-menu {
      position: relative;
      margin-left: 15px;
    }

    .dropdown-menu {
      margin-top: 10px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .logo {
      width: 100%;
      text-align: center;
      padding-left: 35px;

      > a {
        display: inline-block;
      }

      img {
        height: 25px;

        @media (min-width: 350px) {
          height: 30px;
        }
      }
    }
  }
}

  .language {
    position: relative;
    padding-right: 10px;
    display: inline-block;

    &:after {
      right: 0;
      bottom: 3px;
      position: absolute;
      display: inline-block;
      vertical-align: .255em;
      content: "";
      border-top: 5px solid;
      border-right: 3px solid transparent;
      border-bottom: 0;
      border-left: 3px solid transparent;
    }
  }

  .languages {
    font-size: 20px;
    padding-right: 20px;
    position: absolute;
    right: 0px;

    .active {
      position: relative;

      &:after {
        content: '';
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        border-bottom: 1px solid white;
      }
    }
  }

  .search-bar {
    transition: all 0.35s ease;
    background: $theme-brand-color-blue-1;
    height: 0px;
    font-size: $font-size-sm;
    position: relative;
    color: white;

    .links {
      a {
        &,
        &:focus,
        &:hover,
        &:active,
        &:active:focus {
          color: white;
          text-decoration: none;
        }
      }
    }

    .b-mijn-fudura {
      display: inline-block;
      margin-right: 20px;
      float: right;

      .icon-svg {
        margin-right: 5px;
      }
    }

    .b-search {
      display: inline-block;
      margin-right: 30px;
      float: right;
    }

    .links {
      transition: visibility 0s, opacity 0.35s ease;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 0;
      top: 4px;
    }

    .languages {
      transition: visibility 0s, opacity 0.35s ease;
      opacity: 0;
      visibility: hidden;
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 32px);
      position: relative;
      top: 22px;
    }

    .form-search {
      transition: visibility 0s, opacity 0.35s ease;
      opacity: 0;
      visibility: hidden;
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 32px);
      position: relative;
      top: 17px;

      input {
        background: transparent;
        color: white;
        border: 0;
        font-size: $theme-font-size-l;
        border-bottom: 1px solid white;
        width: calc(100% - 100px);
        border-radius: 0;
        margin: 0 20px;
        padding: 5px 0;
      }
      /* Change the white to any color ;) */
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #04296C inset !important;
      }
      /*Change text in autofill textbox*/
      input:-webkit-autofill {
        -webkit-text-fill-color: white !important;
      }

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }
    }
  }

  .ul-paged-menu.search-active {
    transition: height 0.35s ease;
    height: 140px;

    .search-bar {
      height: 80px;
    }

    .links {
      opacity: 0;
      visibility: hidden;
    }

    .languages {
      display: none !important;
    }

    .form-search {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }

  .ul-paged-menu.languages-active {
    transition: height 0.35s ease;
    height: 150px;

    .search-bar {
      height: 80px;
    }

    .links {
      opacity: 0;
      visibility: hidden;
    }

    .form-search {
      display: none !important;
    }

    .languages {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
  /* ------- */
  .ul-paged-menu.active {
    height: 100%;
    position: fixed;
  }


.ul-paged-menu {
  position: absolute;
  font-weight: 600;
  transition: all 0.35s ease;
  width: 100%;
  left: 0;
  height: 70px;
  // overflow:hidden;
  top: 0;
  z-index: 8;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a.btn {
    text-decoration: none;
    margin-top: -5px;
  }
  /* first level */
  > ul > li:last-child {
    padding-top: 50px;
  }



  ul {
    margin: 0;
    padding: 0;
    display: block;
    background: white;
    position: relative;
    height: 100%;
    // margin-right:40px;
    // width:calc(100% - 40px);
    li.logo {
      position: absolute;
      top: -78px;
      display: block;
      padding: 12px 20px 0 20px;

      a {
        display: inline-block;

        img {
          height: 40px;
        }
      }
    }

    li {
      padding: 25px 20px 0 20px;

      a {
        color: $theme-brand-color-blue-2;
        display: block;
      }

      a.btn.btn-style-3.btn-sm {
        display: inline-block;
      }

      a[data-menu-target] {
        position: relative;

        &:after {
          margin-left: 12px;
          position: absolute;
          right: 0;
          top: 8px;
          content: "";
          display: inline-block;
          width: 9px;
          height: 9px;
          border-right: 2px solid black;
          border-top: 2px solid black;
          transform: rotate(45deg);
        }
      }
      /* second level */
      div.header {
        min-height: 65px;
        color: $theme-brand-color-blue-1;
        padding: 25px 20px 5px 20px;
        margin: 0;
        display: inline-block;

        img {
          display: inline-block;
          float: left;
          position: relative;
          top: -4px;
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }

        > a {
          margin: 0;
          padding: 0;
          display: inline-block;

          &:after {
            margin-left: 12px;
            position: relative;
            top: -1px;
            content: "";
            display: inline-block;
            width: 9px;
            height: 9px;
            border-right: 2px solid black;
            border-top: 2px solid black;
            transform: rotate(45deg);
          }
        }
      }
    }

    ul {
      position: relative;

      li {
        display: block;
        float: none;
        padding: 5px 20px 5px 0px;

        a {
          position: relative;
          font-size: $font-size-sm;
          font-weight: normal;
          margin: 0;
          display: block;
          padding-left: 40px;

          &:before {
            position: absolute;
            left: 20px;
            top: 7px;
            margin-right: 12px;
            content: "";
            display: inline-block;
            width: 9px;
            height: 9px;
            border-right: 2px solid $theme-brand-color-gray-2;
            border-top: 2px solid $theme-brand-color-gray-2;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  > ul {
    margin-right: 40px;
  }
}
  /* --- slide menu in from left  on mobile --- */
  .ul-paged-menu {
    transition: left 0.35s ease, height 0s ease 0.5s, background-color .35s ease 0s;
    background-color: transparent;

    > ul {

      li.logo {
        display: none
      }

      transition: left 0.35s ease;
      left: -100%;
      height: 100%;
    }
  }

  .ul-paged-menu.active {
    transition: left 0.35s ease, height 0s ease 0s, background-color .35s ease 0s;
    background-color: rgba(0,0,0,0.4);

    > ul {
      left: 0%;
    }
  }
  /* --- slide menu in from top on desktop --- */
  @media (min-width: map-get($grid-breakpoints, "xxl")) {

    .ul-paged-menu {
      transition: left 0.35s ease, height 0s ease 0.5s, background-color .35s ease 0s;
      background-color: transparent;

      > ul {
        transition: left 0.35s ease;
        left: 0;
        height: 100%;
      }
    }

    .ul-paged-menu.active {
      transition: left 0.35s ease, height 0s ease 0s, background-color .35s ease 0s;
      background-color: rgba(0,0,0,0.4);
    }
  }

  .ul-paged-menu .ul-paged-menu--page {
    background: white;
    box-shadow: 0 2px 17px -8px rgba(0,0,0,0.5);
    transition: all 0.35s ease;
    position: absolute;
    z-index: 10;
    top: 0;
    left: -100%;
    width: 100%;
    height: calc(100% - 70px); // fix for top bar
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
    padding: 10px 0;

    a {
      color: $theme-brand-color-blue-1;
    }
  }

  .ul-paged-menu--b-back {
    font-size: $font-size-sm;
    display: block;
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: 0;
    text-align: left;
    color: $theme-brand-color-gray-1;
    border-bottom: 1px solid $theme-brand-color-gray-3;
    padding: 15px 20px;

    &:before {
      margin-right: 12px;
      content: "";
      position: relative;
      top: -1px;
      display: inline-block;
      width: 9px;
      height: 9px;
      border-right: 2px solid black;
      border-top: 2px solid black;
      transform: rotate(225deg);
    }
  }

  .ul-paged-menu .ul-paged-menu--page.active {
    left: 0;
  }

  .ul-paged-menu--b-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 50px;
    width: 50px;
  }
  /* fix for scrollbar appearing when search is active */
  .search-active .ul-paged-menu .ul-paged-menu--page {
    height: calc(100% - 150px);
  }

  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    /* fix for scrollbar appearing when search is active */
    .search-active .ul-paged-menu .ul-paged-menu--page {
      height: auto;
    }

    body.search-active .page-wrapper {
      padding-top: 150px;
    }

    .ul-paged-menu.search-active {
      .search-bar {
        height: 80px;
      }
    }

    .search-bar {
      height: 0;

      .links {
        transition: visibility 0s, opacity 0.35s ease;
        opacity: 1;
        visibility: visible;
        position: absolute;
        right: 0;
      }

      .form-search {
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;

        input {
          width: calc(100% - 100px);
        }
      }
    }

    .mobile-bar {
      display: none;
    }
    /* search active */
    .ul-paged-menu.search-active {
      height: 140px;

      .search-bar {
        height: 80px;
      }
    }

    .ul-paged-menu.active,
    .ul-paged-menu {
      position: absolute;
      height: 70px;
      min-height: 70px;
      width: 100%;
      overflow: visible;

      > ul {
        width: 100%;
        margin-right: 0;
        height: 70px;
        border-bottom: 1px solid $theme-brown-color-2;

        li.right-menu {
          width: 100%;
        }

        li.logo {
          display: table-cell;
          position: relative;
          top: -2px;
          padding: 21px 40px 0 20px;

          a {
            img {
              height: 31px;
            }
          }
        }

        > li {
          display: table-cell;
          padding: 21px 20px 0 20px;

          &:last-child {
            padding: 21px 20px 0 20px;
          }

          > a {
            white-space: nowrap;
          }

          ul {
            li {
              width: 100%;
              padding: 5px 20px 5px 0px;

              a {
                padding-left: 40px;
              }
              /* overwrite extra padding 'offerte aanvragen' used on mobile*/
              &:last-child {
                padding: 5px 20px 5px 0px;
              }
            }
          }

          a[data-menu-target] {
            &:after {
              position: relative;
              top: -4px;
              border-right: 2px solid black;
              border-top: 2px solid black;
              transform: rotate(135deg);
            }
          }

          a[data-menu-target].active {
            &:after {
              top: 1px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    /* create menu bar bg (needed for z-index, menu-page should appear behind this bar) */
    .ul-paged-menu:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      height: 50px;
      width: 100%;
      background: white;
    }

    .ul-paged-menu .ul-paged-menu--page {
      z-index: -2;
      left: 0;
      top: -1000px;
      min-height: 320px;
      height: auto;
      padding: 20px 0;

      .menu-col {
        width: 33%;
        display: inline-block;
        float: left;
      }

      .menu-col.p-33 {
        width: 33%;
      }

      .menu-col.p-25 {
        width: 25%;
      }

      .menu-col.p-50 {
        width: 50%;
      }
    }

    .ul-paged-menu .ul-paged-menu--page.active {
      left: 0;
      top: 70px;
    }

    .ul-paged-menu li {
      display: inline-block;
    }

    .ul-paged-menu .ul-paged-menu--page li {
      display: block;
    }

    .ul-paged-menu--b-back {
      display: none;
    }

    .ul-paged-menu--b-hamburger {
      display: none;
    }
  }


  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    .ul-paged-menu {
      .ul-paged-menu--page {
        padding: 20px calc((100% - 1264px) / 2);
        padding-bottom: 40px;
      }
    }
  }
