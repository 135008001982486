
/**
 * scss/core/components/on-appear.scss
 * ----------------------------------------------------------
 * Animate components when they appear in viewport
 *
 * @type       Sass
 * @author     PJ Arts    <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.on-appear {
    opacity:0;
    transition: all 0.5s ease;
    transition-property: opacity transform;
    transform: translate(0, 50px);
}

.on-appear.appeared {
    opacity:1;
    transform: translate(0, 0);
    transition-delay: 0s!important;
}

header {
    .block-image-text.on-appear {
        opacity:0;
        transform: translate(0, 0);
        transform: scale(0.9, 0.9);
    }

    .block-image-text.on-appear.appeared {
        opacity:1;
        transform: scale(1, 1);
    }


}
