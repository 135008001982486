
/**
 * scss/core/base/spacers/margin.scss
 * ----------------------------------------------------------
 * Margin spacers...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* all
------------------------------------------------------------ */
.margin-a00 { margin: 0 !important; }

/* top
------------------------------------------------------------ */
.margin-t00 { margin-top: 0 !important; }
.margin-t05 { margin-top: 5px !important; }
.margin-t10 { margin-top: 10px !important; }
.margin-t15 { margin-top: 15px !important; }
.margin-t20 { margin-top: 20px !important; }
.margin-t25 { margin-top: 25px !important; }
.margin-t30 { margin-top: 30px !important; }
.margin-t40 { margin-top: 40px !important; }
.margin-t50 { margin-top: 60px !important; }
.margin-t60 { margin-top: 60px !important; }
.margin-t70 { margin-top: 70px !important; }

.margin-t100 {
    margin-top: 100px !important; 
}

/* bottom
------------------------------------------------------------ */
.margin-b00 { margin-bottom: 0 !important; }
.margin-b05 { margin-bottom: 5px !important; }
.margin-b10 { margin-bottom: 10px !important; }
.margin-b15 { margin-bottom: 15px !important; }
.margin-b20 { margin-bottom: 20px !important }
.margin-b25 { margin-bottom: 25px !important; }
.margin-b30 { margin-bottom: 30px !important; }
.margin-b40 { margin-bottom: 40px !important; }
.margin-b50 { margin-bottom: 60px !important; }
.margin-b60 { margin-bottom: 60px !important; }
.margin-b70 { margin-bottom: 70px !important; }

@media (max-width: $theme-screen-md) {
    .margin-sm-b40 {
        margin-bottom: 40px !important;
    }    
}

/* right
------------------------------------------------------------ */
.margin-r00 { margin-right: 0 !important; }
.margin-r05 { margin-right: 5px !important; }

/* left
------------------------------------------------------------ */
.margin-l00 { margin-left: 0 !important; }
.margin-l05 { margin-left: 5px !important; }