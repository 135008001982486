
/**
 * core/icon/base
 * ----------------------------------------------------------
 * Base icon definitions...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2015 Uselab.com
 *
 */

@import "icon/icon-navigation";