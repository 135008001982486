
/**
 * scss/core/util/mixins.scss
 * ----------------------------------------------------------
 * Application general Mixins...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab.com
 *
 * Index:
 *      blur
 *      hyperlink
 *      vertical-align
 *      vertical-align-relative
 *      horizontal-align
 *      center-align
 *      button-base
 *      button-font
 *      button-background
 *      button-border-color
 *      ul-reset
 *      ul-default
 *      heading-reset
 *      panel-reset
 *      clearfix
 *      icon-base
 *      icon-item
 *
 */

/* blur
------------------------------------------------------------ */
@mixin sixteen-nine($selector:null, $img:false, $align:false) {
    position: relative;
    overflow: hidden;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%;
    }

    @if ($selector) {
        & > #{$selector} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    @if ($img) {
        & > img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;

            @if ($align) {
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
            }
        }
    }
}

/* blur
------------------------------------------------------------ */
@mixin blur($amount) {
    -webkit-filter: blur($amount); -moz-filter: blur($amount);
    -o-filter: blur($amount); -ms-filter: blur($amount);
    filter: blur($amount);
}

/* vertical-align
------------------------------------------------------------ */
@mixin vertical-align() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%); /* IE */
    -moz-transform: translateY(-50%); /* Firefox */
    -webkit-transform: translateY(-50%); /* Safari and Chrome */
    -o-transform: translateY(-50%); /* Opera */
}

/* vertical-align
------------------------------------------------------------ */
@mixin vertical-align-relative() {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%); /* IE */
    -moz-transform: translateY(-50%); /* Firefox */
    -webkit-transform: translateY(-50%); /* Safari and Chrome */
    -o-transform: translateY(-50%); /* Opera */
}

/* horizontal-align
------------------------------------------------------------ */
@mixin horizontal-align() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%); /* IE */
    -moz-transform: translateX(-50%); /* Firefox */
    -webkit-transform: translateX(-50%); /* Safari and Chrome */
    -o-transform: translateX(-50%); /* Opera */
}

/* center-align
------------------------------------------------------------ */
@mixin center-align() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%); /* IE */
    -moz-transform: translate(-50%,-50%); /* Firefox */
    -webkit-transform: translate(-50%,-50%); /* Safari and Chrome */
    -o-transform: translate(-50%,-50%); /* Opera */
}

/* button-base
------------------------------------------------------------ */
@mixin button-base($radius:0, $reset: false, $padding: 10px 14px) {
    background-color: transparent;
    border: 0;

    -webkit-border-radius:  $radius;
    -moz-border-radius:     $radius;
    border-radius:          $radius;

    cursor: pointer;
    display: inline-block;
    text-shadow:0 0 0;

    margin: 0;
    outline: none;
    padding: $padding;

    &:focus,
    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active,
    &:active:focus {

        @if $reset == true {
            background-color: transparent;
            box-shadow: none;
        }

        -webkit-border-radius:  $radius;
        -moz-border-radius:     $radius;
        border-radius:          $radius;

        outline: none;
    }
}

/* button-font
------------------------------------------------------------ */
@mixin button-font($font-color:'', $font-color-hover:'', $font-family:'', $font-size:'', $line-height:'') {

    text-decoration:none;

    @if $font-family != "" {
        font-family: $font-family;
    }
    @if $font-size != "" {
        font-size: $font-size;
    }
    @if $line-height != "" {
        line-height: $line-height;
    }
    @if $font-color != "" {
        color: $font-color;
    }

    &:focus,
    &:hover,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active,
    &:active,
    &:active:focus {

        text-decoration:none;

        @if $font-color-hover != "" {
            color: $font-color-hover;
        } @else {
            color: $font-color;
        }
    }
}

/* button-background
------------------------------------------------------------ */
@mixin button-border-color($normal, $hover:'', $focus:'', $active:'') {
    border-color: $normal;

    &:focus {
        @if $focus != '' {
            border-color: $focus;
        } @else {
            border-color: $normal;
        }
    }
    &:hover {
        @if $hover != '' {
            border-color: $hover;
        } @else {
            border-color: $normal;
        }
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active,
    &:active,
    &:active:focus {
        @if $active != '' {
            border-color: $active;
        } @else {
            border-color: $normal;
        }
    }
}


/* ------------------------------------------------------------ 

New button mixins, used with button property list, for example:
    
$theme-btn-default-bg: #f9f9f9, #f9f9f9, #f9f9f9, #f9f9f9 !default;
$theme-btn-default-border: #f9f9f9, #f9f9f9, #f9f9f9, #f9f9f9, 1px solid !default;
$theme-btn-default-text: white, white, white, white, italic small-caps normal 13px/150% Arial, Helvetica, sans-serif !default;

------------------------------------------------------------ */


/* button-background
------------------------------------------------------------ */
@mixin button-background($normal, $hover:'', $focus:'', $active:'') {
    background-color: $normal;

    &:hover {
        @if $hover != '' {
            background-color: $hover;
        } @else {
            background-color: $normal;
        }
    }
    &:focus {
        @if $focus != '' {
            background-color: $focus;
        } @else {
            background-color: $normal;
        }
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active,
    &:active,
    &:active:focus {
        @if $active != '' {
            background-color: $active;
        } @else {
            background-color: $normal;
        }
    }
}

/* button-text
------------------------------------------------------------ */
@mixin button-text($normal, $hover:'', $focus:'', $active:'', $settings:'') {

    font: $settings;
    color: $normal;
    text-decoration:none;

    a {
        text-decoration:none;
    }

    &:focus {
        text-decoration:none;
        @if $focus != '' {
            color: $focus;
        } @else {
            color: $normal;
        }
    }
    &:hover {
        text-decoration:none;
        @if $hover != '' {
            color: $hover;
        } @else {
            color: $normal;
        }
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active,
    &:active,
    &:active:focus {
        text-decoration:none;
        @if $active != '' {
            color: $active;
        } @else {
            color: $normal;
        }
    }
}

/* button-border
------------------------------------------------------------ */
@mixin button-border($normal, $hover, $focus, $active, $settings) {

    border: $settings $normal;
    border-color: $normal;

    &:focus {
        border-color: $focus;
    }
    &:hover {
        border-color: $hover;
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active,
    &:active,
    &:active:focus {
        border-color: $active;
    }
}












/* ul-reset
------------------------------------------------------------ */
@mixin ul-reset {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        background: none;
        margin: 0;
        padding: 0;
        position: relative;
    }
}

/* ul-default
------------------------------------------------------------ */
@mixin ul-default($align:false, $size: '8px', $color:'', $img:'', $margin-top:6px) {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        padding-left: 15px;
        position: relative;

        ul {
            margin: 0;
            padding: 0 0 0 10px;
            list-style: none;
        }
    }

    li:before {
        @if $img != '' {
            background: url($img) center left no-repeat;
        } @else {
            border-radius: 50%;

            @if $color != '' {
                background-color: $color;
            }
        }

        content: "";
        display: block;
        height: $size;
        margin-top: $margin-top;
        width: $size;
        left: 0;
        position: absolute;

        @if $align == true {
            @include vertical-align();
            margin: 0;
        }
    }
}

/* heading-reset
------------------------------------------------------------ */
@mixin heading-reset {
    font-weight: normal;
    margin: 0;

    strong, b {
        font-weight: normal;
    }
}

/* panel-reset
------------------------------------------------------------ */
@mixin panel-reset {
    border: none;
    box-shadow: none;
    border-radius: 0;
}

/* clearfix
------------------------------------------------------------ */
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/* icon-base
------------------------------------------------------------ */
@mixin icon-base($width, $height, $top:'') {
    background: none center top no-repeat;
    height: $height;
    width: $width;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;

    @if $top != "" {
        top: $top;
    }
}

/* icon-item
------------------------------------------------------------ */
@mixin icon-item($img, $hover:false) {
    background-image: url($img);
    top: -2px;

    // please be careful changing these lines...
    // icon within <a> tag
    a:not(.btn) & {
        top: -1px;
    }
    // icon within .icon-button item
    //  additionally reset a.btn-icon...
    .btn-icon &,
    a.btn-icon & {
        top: 0;
    }

    @if $hover == true {
        a:hover &,
        button:hover &,
        &:hover,
        &:active {
            background-position: center bottom;
        }
    }
}