
/**
 * scss/core/base/base.scss
 * ----------------------------------------------------------
 * Application base styles and browser resets...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

form legend {
    margin: 0;
    padding: 0 0 10px 0;
    border: 0;
}