
/**
 * scss/core/base/hyperlink.scss
 * ----------------------------------------------------------
 * Core stylesheet for hyperlinks...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* default
------------------------------------------------------------ */
a { color: $theme-hyperlink-color; }
a:hover { color: $theme-hyperlink-color-hover; }
a:active { color: $theme-hyperlink-color-active; }