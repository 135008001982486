
/**
 * scss/core/components/youtube-video
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.youtube-player {

    background-color:$theme-brand-color-light;
    position:relative;
    overflow:hidden;
    cursor:pointer;
    text-align:center;
    color:white;

    span, div, h1, h2, h3, h4 {
        color:white;
    }

    iframe {
        opacity:0;
        top:100%; /* enable container click */
        width:100%!important;
        height:100%!important;
        position:absolute;
    }

    .text {
        @include vertical-align();
        padding:0 30px;
        width:100%;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    }

    .text:after {
        display:inline-block;
        content:'';
        width: 0; 
        height: 0;

        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 50px solid $theme-brand-color-dark;
    }
}

.youtube-player.visible {
    iframe {
        opacity:1;
        top:0; left:0;
    }
}

@media (min-width: $theme-screen-md) {
    .youtube-player {

        .text {
            padding:0 25%;
        }

        .text:after {
            margin:25px 0;
            border-top: 60px solid transparent;
            border-bottom: 60px solid transparent;
            border-left: 120px solid $theme-brand-color-dark;
        }
    }
}