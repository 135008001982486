
/**
 * scss/core/base/button.scss
 * ----------------------------------------------------------
 * Stylesheet for bootstrap button (.btn class) adjustments...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.btn.btn-fw {
    width: 100%;
}

/* import
------------------------------------------------------------ */
@import 'btn/btn';
@import 'btn/btn-icon';