
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

header.header-text-image {

    min-height: 1px;

    background-color:$theme-brand-color-gray-3;

    .image {
        display:none;
        position:relative;
        top:0; right:0;
        width:100%;
        min-height:200px;
        padding-bottom: 56.25%; /* 16:9 */

        img {
            margin-bottom:0;
        }
    }
  
    .container {

        padding-bottom:0;
        padding-top:0;

        .text {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}

header.header-text-image[data-header-size="Klein"] {
    .image {
        display:none;
    }
    .vertical-align {
        display:block;
        min-height:150px;
        padding:40px 0;
    }
    .col-md-6 {
        flex: 0 0 100%;
        width:100%;
        max-width:100%;
    }
}


/* wteaks to fit the 'Afbeelding vast' in the header on small screens */
header.header-text-image[data-image-style="Afbeelding vast"],
header.header-text-image[data-image-style="Afbeelding vast (donker)"] {
    .image[data-background-image] {
        background-size: auto 370px!important;
        background-repeat: no-repeat;
        background-position:35px center;
    }
}
@media (min-width: 1350px) {
    header.header-text-image[data-image-style="Afbeelding vast"],
    header.header-text-image[data-image-style="Afbeelding vast (donker)"] {
        .image[data-background-image] {
            background-position:20% center;
        }
    }
}

@media (min-width: $theme-screen-md) {

    header.header-text-image,
    header.header-text-image[data-header-size="Medium"] {
        .vertical-align {
            min-height:290px ;
        }
    }

    header.header-text-image[data-header-size="Groot"] {
        .vertical-align {
            min-height:370px;
        }
    }

    header.header-text-image[data-image-style="Afbeelding vast (donker)"] {
        .image[data-background-image] {
            background-color: #dbd7d3;
        }
    }

    header.header-text-image {

        .text {
            padding-right:30px;
            // margin-top:-30px;
        }

        .image {
            padding-bottom: 0;
            display:block;

            &:before {
                position:absolute;
                content:'';
                background:url('/Assets/img/header-img-triangle.svg') no-repeat left top;
                background-size:100% 100%;
                width:50px;
                height:calc(100% + 1px);
            }

            position:absolute;
            top:0; right:0;
            width:50%;
            height:100%;
        }
    }
}