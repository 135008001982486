
/**
 * scss/core/components/nav.scss
 * ----------------------------------------------------------
 * Navigation component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* import
------------------------------------------------------------ */
@import 'nav/main-primary';
@import 'nav/main-secondary';
@import 'nav/main-sticky';
@import 'nav/pills';