
/**
 * scss/core/components/spinner.scss
 * ----------------------------------------------------------
 * Spinner component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-spinner-color:  $theme-brand-color-primary !default;

.spinner:not(.spinner--*) {
    @include horizontal-align();
    height: 40px;
    margin: 20px 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 40px;
    z-index: $theme-z-index-overlay;
}

.spinner:not(.spinner--*).visible {
    opacity: 1;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

// vue js component spinner...
.spinner[class*="spinner--"] {
    @include horizontal-align();
    position: absolute !important; // important because we need to overrule component styles...
    bottom: 0;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $theme-spinner-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
}