
/**
 * scss/core/components/header-interview
 * ----------------------------------------------------------
 * Block with an image and text and optional footer
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */



.block-quote {
    
    .slimmage {
        margin:0;
    }

    blockquote {
        position:relative;
        padding:0;
        padding-left:0;
        padding-top:45px;
        border-left:0;
        margin-bottom:0;
        font-size:$theme-font-size-l;
        font-weight:600;
        font-style:italic;
        color: $theme-brand-color-blue-1;
    }

    blockquote:before {
        position:absolute;
        content:'';
        background:url(/assets/img/quote.svg);
        display:block;
        left:0; top:8px;
        width:40px; height:24px;
        background-size:40px 24px;
        margin-bottom:20px;
    }

    div.padding {
        padding-left:0;
        blockquote {
            left:0;
        }
    }

    p.name-logo,  p.name {
        font-size:$theme-font-size-l;
        position:relative;
        font-weight: 600;
        color:$theme-brand-color-gray-1;
        margin-top:0.9em;
        margin-bottom:1.3em;
    }

    p.name-logo {
        margin-top:0.4em;
        margin-bottom:1.4em;
        padding-left:53px;
        padding-top: 7px;
        position:relative;

        img {
            position:absolute;
            left:0; top:0;
            height:40px!important;
        }
    }
}


@media (min-width:  map-get($grid-breakpoints, "lg")) {

    .block-quote {

        div.padding {
            padding-left: 80px;
            padding-top: 0px;

            blockquote {
                margin-top: -10px; // compensate line height
                left: -60px;
                padding-left: 60px;
                padding-top: 3px;
                padding-top: 0px;
   
            }

            blockquote:before {
                width:40px; height:24px;
                background-size:40px 24px;
            }
        }
    }

}

@media (min-width:  map-get($grid-breakpoints, "xl")) {
    .block-quote {
        div.padding {
            blockquote {
                font-size: 24px;
            }
            blockquote:before {
                top:10px;
            }
        }
    }
}
