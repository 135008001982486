
/**
 * scss/core/components/alert/info.scss
 * ----------------------------------------------------------
 * Alert info component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.alert.alert-info {
    background: $theme-alert-color-info;
    border-color: $theme-alert-color-info;
    color: white;
}