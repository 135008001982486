
/**
 * scss/core/base/form/fieldset.scss
 * ----------------------------------------------------------
 * Fieldset...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab.com
 *
 */

.fieldset.fieldset-footer {
    margin: 0;
}
.fieldset + .fieldset {
    margin-top: 20px;
} 