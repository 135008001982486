
/**
 * scss/core/layout/grid-sections.scss
 * ----------------------------------------------------------
 * These classes can be applied to the rows of a grid editor
 * page in the CMS...
 *
 * @type       Sass
 * @author     PJ Arts  <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 * Options in the grid editor:
 * ----------------------------------------------------------
 *  [class]
 *      .section-transparent
 *      .section-white
 *      .section-default
 *      .section-primary
 *      .section-secondary
 *
 *  [data-row-offset]
 *      true
 *      false
 *
 *  [data-section-min-height]
 *      auto
 *      medium
 *      large
 *
 *  [data-row-align]
 *      left
 *      center
 *      right
 *
 */

.row  {
    .column {
        margin-bottom:30px;
    }
    .column:last-child {
        margin-bottom:0;
    }
}

@media (min-width:  map-get($grid-breakpoints, "lg")) {
    .row  {
        .column {
            margin-bottom:0;
        }
    }
}


.section-gray {
    background-color:$theme-brand-color-gray-3;
}

.section-gray-overlap  {
    position:relative;
    background-color:$theme-brand-color-gray-3;
}

@media (min-width: map-get($grid-breakpoints, "xl")) {

    .section-gray-overlap {

        .column:first-child {
            margin-bottom: 50px; // extra spacing for text...
        }
        
        /* fix for carousel - carousel is nested in a full width column */
        .column.col-lg-12 {
            margin-bottom: 0px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            height: calc( #{$theme-section-padding-md} + 40px);
            background: white;
        }
    }

}

[data-col-color="light"] {
    background-color: $theme-brand-color-gray-1;
    padding: 20px;

    @media (min-width: $theme-screen-sm) {
        padding: 40px;
    }

    @media (min-width: $theme-screen-md) {
        padding: 60px 100px;
        padding-bottom: calc(60px - 1.5em);
    }
}

/* row separator
------------------------------------------------------------ */
.grid-section > [data-row-separator="arrow"] {
    position:relative;
    padding-bottom:60px; /* make room for arrows */
}

[data-row-separator="arrow"]:after {
    position:absolute;
    bottom:0;
    width:100%;
    content:'';
    display:block;
    height:60px;
    background:url(/assets/img/icons/icon-break.svg) no-repeat center 10px;
}

/* --- alt vertical align  */
.vertical-align-ie-fix {
    display: flex;
}

.vertical-align 
{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;

    .vertical-align-content {
        width:100%;
    }
}

.section-blue {

    background: #00AFB2;
    position:relative;

    p, span, h1, h2, h3, h4, h5, ul, a {
        color:white;
    }
    
    .column > div > img.slimmage {
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    }

    &:before {
        position:absolute;
        content:'';
        background:url('/Assets/img/row-triangle-blue.svg') no-repeat left top;
        background-size:100% 100%;
        width:calc(100%);
        height:100px;
        bottom:0;
        left:0;
    }
}


.grid-section .section-blue-triangle {

    .column:first-child {
     //   padding-bottom:60px;
    }

    background: #00AFB2;
    position:relative;
    padding-bottom:0px;
    margin-bottom:60px;

    p, span, h1, h2, h3, h4, h5, ul, a {
        color:white;
    }

    .column > div > img.slimmage {
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    }

    .column:last-child {
        margin-bottom:30px;
    }

    p {
        font-size:  $theme-font-size-l;
    }
}

@media (min-width: $theme-screen-md) {

    [data-col-valign="center"] {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .grid-section .section-blue-triangle {

        .column:first-child {
            padding-bottom:60px;
        }

        &:before {
            position:absolute;
            content:'';
            background:url('/Assets/img/row-triangle-white.svg') no-repeat left top;
            background-size:100% 100%;
            width:calc(100%);
            height:100px;
            bottom:0;
            left:0;
        }

        .column:last-child {
            margin-bottom:30px;
        }

        p {
            font-size:  $theme-font-size-xl;
        }
    }
}

[data-row-separator="true"] {
    border-top:1px solid $theme-brand-color-gray-2;
}