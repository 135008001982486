
/**
 * scss/core/components/block-image-text.scss
 * ----------------------------------------------------------
 * Block with an image and text and optional footer
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* hover animation (dark) */
a.block-text-on-image:before {
    content:'';
    background:black;
    position:absolute;
    left:0; right:0; bottom:0; top:0;
    opacity:0;
    transition: opacity 0.3s ease;
    z-index:0;
}
a.block-text-on-image:hover:before {
    opacity:0.35;
}

/* hover animation (light) */
a.block-text-on-image.secondary:before {
    background:white;
}
a.block-text-on-image.secondary:hover:before {
    opacity:0.35;
}

/* add gradient for better readablity */
.block-text-on-image:not(.secondary) {

    .content,
    .text,
    .footer {
        z-index:1;
    }

    &:after {
        content:'';
        background:black;
        position:absolute;
        left:0; right:0; bottom:0; top:33%;
        z-index:0;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a3000000',GradientType=0 ); /* IE6-9 */     
    }
}

.block-text-on-image {

    min-height:270px;
    position:relative;

    /* bel mij terug form in block */
    .form-wrapper {
        margin:0;

        text-align:left;

        .input-container {
            border-color:white;
        }

        .input-container.input-error {
            border-color: $theme-form-color-error; 
        }

        label,
        .tooltip,
        h4 {
            display:none!important;
        }

        input[type=submit]:before {
            content:"Bel mij terug";
        }
    }
}
