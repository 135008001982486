
/**
 * scss/site/modules/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @copyright  2018 Uselab.com
 *
 */

/* --- specific form field columns --- */
@media (min-width: $theme-screen-sm) {

    .contourFieldSet > legend {
        display:none;
    }

    .contourNavigation {
        clear:both;
    }

    /*
    .umbraco-forms-field {

        &.dropdown,
        &.shortanswer {
            
            float:left;
            width:calc(50%);
            padding:0 10px 0 0;
        }

        &.multiplechoice,
        &.longanswer,
        &.checkbox  {
            clear:both;
        }
    }

    // left column
    .umbraco-forms-field {

        clear:both;

        &.bedrijfsnaam,
        &.voornaam,
        &.contactpersoon,
        &.postcode,
        &.telefoonnr {
            float:left;
            width:calc(50%);
            padding:0 10px 0 0;
        }
    }

    // right column
    .umbraco-forms-field {

        &.eancode,
        &.adres,
        &.plaats,
        &.emailadres {

            clear:none;
            float:left;
            width:calc(50%);
            padding:0 0 0 10px;
        }
    }

*/
}
