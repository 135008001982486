
/**
 * scss/core/components/alert.scss
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-alert-color-default:                     $theme-color-state-default !default;
$theme-alert-color-info:                        $theme-color-state-info !default;
$theme-alert-color-success:                     $theme-color-state-success !default;
$theme-alert-color-warning:                     $theme-color-state-warning !default;
$theme-alert-color-danger:                      $theme-color-state-danger !default;

.response-alert {
    position: fixed;
    top: 30px;
    left: 5px;
    right: 5px;
    text-align: left;
    z-index: $theme-z-index-overlay;
    pointer-events: none;

    .alert {
        background: white;
        background: $theme-alert-color-default;
        border-color: $theme-alert-color-default;

        -webkit-box-shadow: 0 0 33px -5px rgba(0,0,0,0.31);
        -moz-box-shadow: 0 0 33px -5px rgba(0,0,0,0.31);
        box-shadow: 0 0 33px -5px rgba(0,0,0,0.31);

        color: $theme-body-font-color;
        max-width: 780px;
        margin: 0 auto;
        overflow: hidden;
        opacity: 0;
        padding: 15px;

        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
    }
    .alert:last-child {
        margin-bottom: 0;
    }
    .alert-in {
        opacity: 1;
    }
    .alert + .alert {
        margin-top: 5px; // value also used hard coded in js component!
    }
}

// -----------------------------------------------------------------------------------------
// import
// -----------------------------------------------------------------------------------------

@import "alert/danger";
@import "alert/info";
@import "alert/success";
@import "alert/warning";
