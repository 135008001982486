
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

header.header-full-width {  

    .column {
        position:relative;
    }

    .container, .column {
        padding-top:0;
        padding-bottom:0;
        min-height:200px;

        h1 {
            position:absolute;
            bottom:0;
        }
    }
}

@media (min-width: $theme-screen-sm) {

    header.header-full-width {
        .container, .column {
            min-height:350px;
        }
    }
}

@media (min-width: $theme-screen-md) {
    header.header-full-width {
        .container, .column {
            min-height:500px;
            margin-bottom:0px;
        }
    }
}

@media (min-width: $theme-screen-lg) {
    header.header-full-width {
        .container, .column {
            min-height:646px;
        }
    }
}