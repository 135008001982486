
/**
 * scss/core/components/nav/main-primary.scss
 * ----------------------------------------------------------
 * Navigation main primary...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-nav-main-background:                     white !default;
$theme-nav-main-height:                         44px !default;
$theme-nav-main-toggle-size:                    44px !default;
$theme-nav-main-toggle-icon-sprite:             '/assets/img/nav-main-primary-toggle-sprite.svg' !default;
$theme-nav-main-link-color:                     white !default;
$theme-nav-main-link-padding:                   20px !default;

// -----------------------------------------------------------------------------------------
// styles
// -----------------------------------------------------------------------------------------

div#nav-main-primary {
    background: $theme-nav-main-background;
    height: $theme-nav-main-height;
    position: relative;

    button {
        @include button-base(0, false, 0);
        @include vertical-align();
        display: block;
        position: absolute;
        right: 5px;
        width: $theme-nav-main-toggle-size;
        height: $theme-nav-main-toggle-size;
    }

    .button-toggle {
        background: url($theme-nav-main-toggle-icon-sprite) 0 -#{$theme-nav-main-toggle-size} no-repeat;
        width: $theme-nav-main-toggle-size;
        height: $theme-nav-main-toggle-size;
    }
    .button-toggle.collapsed {
        background-position: 0 0;
    }

    nav {
        background: $theme-nav-main-background;
        position: absolute;
        top: $theme-nav-main-height;
        left: 0;
        right: 0;
        z-index: 1000;

        ul {
            @include ul-reset();

            li a {
                display: block;
                padding: 0 $theme-nav-main-link-padding $theme-nav-main-link-padding $theme-nav-main-link-padding;
                text-align: center;
            }
            li:first-child a {
                padding-top: $theme-nav-main-link-padding;
            }
            li a, li a:hover {
                color: $theme-nav-main-link-color;
            }
        }
    }
}
