
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

header {
    background: $theme-header-background;
    position: relative;
    min-height: 400px;
    z-index: 0;
    overflow:hidden;

    .container {
        padding-top: 60px;
        padding-bottom: 60px;

        .column:last-child {
            padding-bottom: 0;
        }
    }
    .section-default:not(:last-child) {
        padding-bottom: 0;
    }
}

header.header-image {
    background-size: cover;
    background-position: center center;
}

header video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
}

/* ------- medium screens ------- */
@media (min-width: $theme-screen-sm) {
    header video {
        height:auto;
        min-height: 100%;
        min-width: 100%;
    }
}