
/**
 * core/base/btn/btn-icon.scss
 * ----------------------------------------------------------
 * Icon buttons...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* btn-icon
------------------------------------------------------------ */
.btn-icon {
    @include button-base($theme-btn-base-border-radius, true, 0);
    border: 2px solid;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

/* default
------------------------------------------------------------ */
.btn-icon.btn-default {
    @include button-background($theme-btn-icon-default, $theme-btn-icon-default-hover, $theme-btn-icon-default-focus, $theme-btn-icon-default-active);
    @include button-border-color($theme-btn-icon-default, $theme-btn-icon-default-hover, $theme-btn-icon-default-focus, $theme-btn-icon-default-active);
}

/* primary
------------------------------------------------------------ */
.btn-icon.btn-primary {
    @include button-background($theme-btn-icon-primary, $theme-btn-icon-primary-hover, $theme-btn-icon-primary-focus, $theme-btn-icon-primary-active);
    @include button-border-color($theme-btn-icon-primary, $theme-btn-icon-primary-hover, $theme-btn-icon-primary-focus, $theme-btn-icon-primary-active);
}

/* secondary
------------------------------------------------------------ */
.btn-icon.btn-secondary {
    @include button-background($theme-btn-icon-secondary, $theme-btn-icon-secondary-hover, $theme-btn-icon-secondary-focus, $theme-btn-icon-primary-active);
    @include button-border-color($theme-btn-icon-secondary, $theme-btn-icon-secondary-hover, $theme-btn-icon-secondary-focus, $theme-btn-icon-primary-active);
}

/* state: info
------------------------------------------------------------ */
.btn-icon.btn-info {
    @include button-background($theme-color-state-info, darken($theme-color-state-info, 5%), darken($theme-color-state-info, 10%), darken($theme-color-state-info, 15%));
    @include button-border-color($theme-color-state-info, darken($theme-color-state-info, 5%), darken($theme-color-state-info, 10%), darken($theme-color-state-info, 15%));
}

/* state: success
------------------------------------------------------------ */
.btn-icon.btn-success {
    @include button-background($theme-color-state-success, darken($theme-color-state-success, 5%), darken($theme-color-state-success, 10%), darken($theme-color-state-success, 15%));
    @include button-border-color($theme-color-state-success, darken($theme-color-state-success, 5%), darken($theme-color-state-success, 10%), darken($theme-color-state-success, 15%));
}

/* state: warning
------------------------------------------------------------ */
.btn-icon.btn-warning {
    @include button-background($theme-color-state-warning, darken($theme-color-state-warning, 5%), darken($theme-color-state-warning, 10%), darken($theme-color-state-warning, 15%));
    @include button-border-color($theme-color-state-warning, darken($theme-color-state-warning, 5%), darken($theme-color-state-warning, 10%), darken($theme-color-state-warning, 15%));
}

/* state: danger
------------------------------------------------------------ */
.btn-icon.btn-danger {
    @include button-background($theme-color-state-danger, darken($theme-color-state-danger, 5%), darken($theme-color-state-danger, 10%), darken($theme-color-state-danger, 15%));
    @include button-border-color($theme-color-state-danger, darken($theme-color-state-danger, 5%), darken($theme-color-state-danger, 10%), darken($theme-color-state-danger, 15%));
}
