
/**
 * scss/core/components/accordion.scss
 * ----------------------------------------------------------
 * Accordion component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-breadcrumb-bg-color:                     $theme-brand-color-primary !default;
$theme-breadcrumb-divider-color:                white !default;
$theme-breadcrumb-link-color:                   white !default;
$theme-breadcrumb-active-color:                 white !default;
$theme-breadcrumb-padding:                      6px 12px !default;

ol.breadcrumb {
    background-color: $theme-breadcrumb-bg-color;
    padding: $theme-breadcrumb-padding;
}

ol.breadcrumb li a {
    color: $theme-breadcrumb-active-color;
}

ol.breadcrumb > .active {
    color: $theme-breadcrumb-active-color;
}

ol.breadcrumb > li + li:before {
    color: $theme-breadcrumb-divider-color;
}