
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

header.header-home {

    min-height:0;

    h1 {
        display:table-cell;
        text-align:center;
        vertical-align:middle;
        height:200px;
    }
}

header.header-image.header-home {
    .container {
        padding-top: 30px;
        padding-bottom: 50px;
        margin-bottom:30px;
    }
}

@media (min-width: $theme-screen-md) {
    header.header-home {

        .container {
            padding-top: 60px;
            margin-bottom:30px;
        }

        h1 {
            text-align: center;
            height: 300px;
            padding: 0 10%;
        }
    }
}

.container-search {

    margin-top:-65px;

    h2 {
        display:inline-block;
    }

    /* hide 'Laatste vacature toegevoegd...' on small screens */
    p.small {
        font-style:italic;
        display:none;
        padding:0;
        margin:0;
    }

    @media (min-width: $theme-screen-md) {
        p.small {
            display:block;
            float:right;
            margin-top:5px;
        }
    }

    .background {

        background:$theme-brand-color-gray-1;
        padding:30px;

        .input-container.input-search input[type="submit"] {
            width:100px;
            text-indent:0;
        }
    }
}