
/**
 * scss/core/components/pagination.scss
 * ----------------------------------------------------------
 * Pagination component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-pagination-text:                         #1d1d1d !default;
$theme-pagination-background:                   #f0f0f0 !default;
$theme-pagination-border:                       #f0f0f0 !default;
$theme-pagination-hover-background:             #e2e2e2 !default;
$theme-pagination-hover-border:                 #e2e2e2 !default; 

.pagination li {
    display: inline-block;
}
.pagination li + li {
    margin-left: -4px;
}
.pagination > li > a,
.pagination > li > span {
    background-color: $theme-pagination-background;
    border-color: $theme-pagination-border;
    color: $theme-brand-color-primary;
    padding: 10px 15px 10px 15px;
}
.pagination > li > span {
    cursor: default;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 11px;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
    z-index: 2;
    color: $theme-pagination-text;
    background-color: $theme-pagination-hover-background;
    border-color: $theme-pagination-hover-border;
}
.pagination li a span.arrow {
    display: block;
    width: 15px;
    height: 23px;
    margin: 0 auto;
    text-indent: 1000px;
    overflow: hidden;
}
.pagination li a span.arrow.arrow-left { background-image: url(/assets/img/arrow-left.svg); }
.pagination li a span.arrow.arrow-left.arrow-disabled { background-image: url(/assets/img/arrow-left-disabled.svg); }
.pagination li a span.arrow.arrow-right { background-image: url(/assets/img/arrow-right.svg); }
.pagination li a span.arrow.arrow-right.arrow-disabled { background-image: url(/assets/img/arrow-right-disabled.svg); }