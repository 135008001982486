
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.tooltip {

    width:300px;
    top:-5px!important;

    .tooltip-inner {
        border-radius:5px;
        background:$theme-brand-color-gray-1;
        font-size: 13px;
        font-weight: normal;
        padding:10px 10px 15px 10px;
        font-family: $theme-brand-font-family;
    }
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: $theme-brand-color-gray-1 !important;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: $theme-brand-color-gray-1 !important;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: $theme-brand-color-gray-1 !important;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: $theme-brand-color-gray-1 !important;
}

