/**
 * myfudura top navigation
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

.nav-top {
  height: $nav-top-height-sm;
  padding: 13px 0;
  border-bottom: 1px solid $theme-brand-color-primary;
  text-align: center;
  position: relative;
  z-index: 1;

  &__logo {
    img {
      width: 120px;
      height: 32px
    }
  }
}

@media (min-width: $theme-screen-md) {
  .nav-top {
    height: $nav-top-height-md;
    border-bottom: 0;
    padding: 19px 0 41px 0;

    &__logo {
      img {
        width: 120px;
        height: 32px
      }
    }
  }
}
