
/**
 * scss/site/modules/umbraco-forms.scss
 * ----------------------------------------------------------
 * Form styles...
 *
 * @type       Sass
 * @copyright  2018 Uselab.com
 *
 */

@import 'form-contact.scss';

.umbraco-forms-container {
    padding:0;
}

.umbraco-forms-page {
    .col-md-12 {
        padding:0;
    }

    br {
        display:none;
    }
}

.umbraco-forms-label, .umbraco-forms-label.fieldLabel, .fieldLabel {
    font-size: $font-size-sm;
    color: $theme-brand-color-gray-1;
}


.umbraco-forms-field-wrapper {
  position: relative;
  padding-bottom: 20px;

  textarea,
  input[type=password],
  input[type=text] {
    width: 100%;
    padding: 5px 12px;
    min-height: 40px;
    font-size: $font-size-sm;
    color: $theme-brand-color-gray-4;
    border-radius: 4px;
    border: 1px solid $theme-brand-color-gray-2;
    padding-right: 30px;
  }

  textarea {
    min-height: 150px;
  }

  textarea:focus,
  input[type=password]:focus,
  input[type=text]:focus {
    border: 1px solid #4DABDD;
    color: $theme-brand-color-gray-1;
  }


  .text.input-validation-error {
    border: 1px solid $theme-error-color;
  }

  .field-validation-error {
    color: $theme-error-color;
    font-size: $font-size-sm;
  }

  textarea + .field-validation-error,
  input[type=password] + .field-validation-error,
  input[type=text] + .field-validation-error {

    &:after {
      content: '';
      background-color: white;
      background-image: url('../../assets/img/icons/form/input-error.svg');
      background-position: 0 4px;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      position: absolute;
      top: 8px;
      right: 3px;
      z-index: 999;
      pointer-events: none; /* allow last pass click */
    }
  }

  .text.valid {
    border: 1px solid $theme-success-color;
  }

  .valid + .field-validation-valid {
    background-color: white;
    background-image: url('../../assets/img/icons/form/input-ok.svg');
    background-position: 0 4px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    color: $theme-error-color;
    font-size: $font-size-sm;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 8px;
    right: 3px;
    z-index: 999;
    pointer-events: none; /* allow last pass click */
  }
  /* --- checkbox / radio --- */
  // chrome bugfix, because chrome doesn't recognize svg :target selector...
  input[type=radio]:checked + label:before,
  input[type=checkbox]:checked + label:before {
    border: 0;
  }

  input[type=radio],
  input[type=checkbox] {
    position: absolute;
    visibility: hidden;
  }

  input[type=radio] + label,
  input[type=checkbox] + label {
    position: relative;
    display: block;
    padding: 0 20px 6px 30px;
    margin: 0;
    font-size: $font-size-sm;
    color: $theme-brand-color-gray-1;
    min-height: $theme-form-element-height;
  }

  input[type=radio]:checked + label,
  input[type=checkbox]:checked + label {
    color: black;
  }

  input[type=radio] + label:before,
  input[type=checkbox] + label:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 1px;
    width: $theme-form-element-height;
    height: $theme-form-element-height;
  }

  input[type=radio] + label:before {
    border-radius: 10px;
    border: 1px solid #cccccc;
    height: 20px;
    width: 20px;
  }

  input[type=radio]:checked + label:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 6px solid #4DABDD;
  }

  input[type=checkbox] + label:before {
    border-radius: 4px;
    border: 1px solid #cccccc;
    height: 20px;
    width: 20px;
  }

  input[type=checkbox]:checked + label:before {
    border: 1px solid #4DABDD;
    border-radius: 4px;
    background: #4DABDD url(../../assets/img/icons/form/checkmark-white.svg) no-repeat 2px 2px;
    background-size: 14px 14px;
  }

  input[type=radio] + label.inline,
  input[type=checkbox] + label.inline {
    display: inline-block;
  }
}

/* select box */
.umbraco-forms-field.dropdown > div {

    overflow: hidden;
    position: relative;
    width:100%;
    font-size: $font-size-sm;
    color:$theme-brand-color-gray-4;

    // hide default select element on ie 10+
    select::-ms-expand {
        display: none;
    }

    select {
        border-radius:4px;
        border:1px solid $theme-brand-color-gray-2; 
        min-height: 40px;
        line-height:25px;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: transparent no-repeat -100px 0;
        color:$theme-brand-color-gray-4;
        padding:5px 12px;
        width: 100%;
    }

    select:focus {
        border:1px solid #4DABDD;
        color:$theme-brand-color-gray-1;
    }

    &:after {
        margin-right:12px;
        content: "";
        position: absolute;
        top:13px; right:3px;
        display: inline-block;
        width: 9px;
        height: 9px;
        border-right: 2px solid black;
        border-top: 2px solid black;
        transform: rotate(135deg);
        pointer-events:none;
    }   
}

.umbraco-forms-tooltip.help-block {
    font-size: $font-size-sm;
    padding-bottom: 1em;
    display:block;
}

.umbraco-forms-field-tooltip {
    width:calc(100% - 30px)!important;
    display:inline-block;
}

.umbraco-forms-field-tooltip + .icon-svg.icon-info {
    float:right;
    top:7px;
    padding:0;
    width:20px;
    height:20px;
}

.validation-summary-errors {
  ul {
    @include ul-reset();

    li {
      background-image: url('../../assets/img/icons/form/input-error.svg');
      padding-left: 25px;
      background-position: 0 4px;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      padding-bottom: 1em;
      font-size: $font-size-sm;
      color: $theme-error-color;
    }
  }
}