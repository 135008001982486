
/**
 * scss/core/layout/grid-sections.scss
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts  <pj.arts@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

// extra margin needed for nav-left
@media (min-width: $theme-screen-md) {
  .main-wrapper {
    min-height: calc(100% - #{$nav-top-height-md} - #{$footer-height});
    .container {
      padding: 0 100px;
    }
  }
}

// no extra margin needed for nav-left
@media (min-width: $theme-screen-xl) {
  .main-wrapper {
    .container {
      padding: 0;
    }
  }
}

/* remove top padding from first section below the header */
.grid-wrapper .umb-grid:first-child .grid-section:first-child .section-default:first-child {
  padding-top: 0;
}