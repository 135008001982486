
/**
 * scss/core/components/alert.scss
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */


.block-image-text,
.block-image-text.primary {
    background: transparent;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);

    h3 {
        word-break: break-word;
    }

    div.image {
        background:$theme-brand-color-gray-3;
        text-align:center;
    }
    
    div.image.max-h  {
        img {
             max-height:175px!important;
        }
    }

    div.text {
        padding-top:20px;
        padding-bottom:30px;
    }
}

.block-image-text.geen-schaduw, /* umbraco classname */
.block-image-text.secondary {
    box-shadow: 0 0 0 0;
    background: transparent;
    div.footer,
    div.text {
        padding-left:0;
        padding-right:0;
    }
    div.text {
      //  padding-top:0;
    }

    div:last-child {
        padding-bottom:0;
    }
}

.block-image-text { 
    .header-img {
        img, img.slimsy {
            margin:0 0 1em 0;
            width:90px;
            height:120px!important;
        }
    }
}

.block-icon-text {
    .header-img.vertical-align {
        margin-bottom:0;
    }
}

@media (min-width:  map-get($grid-breakpoints, "xl")) {

    .block-image-text { 

        .header-img {
        
            position:relative;
            min-height:120px;
            padding-bottom:20px;
            padding-left:110px;
        
            h1, h2, h3, h4, h5 {
                margin-bottom:0;
            }

            img, img.slimmage {
                margin:0;
                position:absolute;
                left:0;
                top:0;
            }
        }
    }

    .block-icon-text {
        .header-img.vertical-align {
            margin-bottom:1em;
        }
    }
}

.section-gray {
    .block-image-text {
        background-color:white;
    }
}

/* icon text */
.block-image-text.block-icon-text {
    div.text {
        padding-top:20px;
        padding-bottom:30px;
    } 
}

.block-image-text.block-icon-text.geen-schaduw {
    div.text {
        padding-top:0;
        padding-bottom:0;
    } 
}