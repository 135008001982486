
/**
 * scss/core/components/slick-column-slider
 * ----------------------------------------------------------
 * Slick Column Slider
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

$theme-nav-sticky-bar-h-mobile: 54px;
$theme-nav-sticky-bar-h-desktop: 107px;
$theme-nav-sticky-element-h-mobile: 45px!default;
$theme-nav-sticky-element-h-desktop: 45px!default;
$theme-nav-sticky-animation-speed: 0.25s!default;

// -----------------------------------------------------------------------------------------
// body padding
// -----------------------------------------------------------------------------------------
body[data-sticky-menu-scroll] {
    padding-top: $theme-nav-sticky-bar-h-mobile;
}

@media (min-width: $theme-screen-md) {
    body[data-sticky-menu-scroll] {
        padding-top: $theme-nav-sticky-bar-h-desktop;
    }
}

// -----------------------------------------------------------------------------------------
// animations
// -----------------------------------------------------------------------------------------

div#nav-sticky {
    transition: all $theme-nav-sticky-animation-speed ease;
    -webkit-transition: all $theme-nav-sticky-animation-speed ease;

    transition-property: top;
    -webkit-transition-property: top;
    
    /*
        PIE: Disabled for now, does not work on ipad

        nav.collapsing,
        nav {
            transition: height $theme-nav-sticky-animation-speed ease;
            -webkit-transition: height $theme-nav-sticky-animation-speed ease;
        }
    */

}

// -----------------------------------------------------------------------------------------
// mobile version
// -----------------------------------------------------------------------------------------

div#nav-sticky {

    width:100%;
    padding:0 15px;
    background: $theme-nav-main-background;
    height: $theme-nav-sticky-bar-h-mobile;
    border-bottom: 1px solid $theme-brand-color-light;
    position:fixed;
    top:0px;
    z-index:1000;

    /* proportional scalable background */
    a.brand-logo {
        height:$theme-nav-sticky-element-h-mobile;
        @include vertical-align();
        margin-top:6px;
        display:block;
        position:relative;
        width:100%;
        max-width:160px;
        text-indent:160px;
        overflow:hidden;
        white-space:nowrap;
        font-size:0;

        &:after {
            content:'';
            display:block;
            background:url(/assets/img/logo.svg) no-repeat;
            background-size:100% auto;
            width:100%;
            padding-bottom:30%;
        }
    }

    .button-toggle {
        background: url($theme-nav-main-toggle-icon-sprite) 0 100% no-repeat;
        background-size:100% 200%;

        right:0;
        border:0;
        width: $theme-nav-sticky-element-h-mobile; 
        height: $theme-nav-sticky-element-h-mobile;
        display: block;

        @include vertical-align();
    }

    .button-toggle.collapsed {
        background-position: 0 0;
    }

    nav {
        background: $theme-nav-main-background;
        position: absolute;
        top: $theme-nav-sticky-bar-h-mobile;
        width:100%;
        left:0;

        ul {
            @include ul-reset();

            li > a {
                display: block;
                padding:20px;
                text-align: center;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------
// desktop version
// -----------------------------------------------------------------------------------------

@media (min-width: $theme-screen-md) {
    div#nav-sticky {

        height: $theme-nav-sticky-bar-h-desktop;

        .brand-logo img {
            height:$theme-nav-sticky-element-h-desktop;
        }

        .button-toggle {
            width: $theme-nav-sticky-element-h-desktop; height: $theme-nav-sticky-element-h-desktop;
        }

        nav[style] { /* overrule collapse height */
            height: auto!important;
        }

        nav {

            background:transparent;
            display: block;
            @include vertical-align();
            right: 0; left:auto;
            width:auto;

            ul {
                li {
                    float: left;
                    > a {
                        padding:20px;
                    }
                }
            }
        }

        .button-toggle {
            display: none;
        }
    }
}


// -----------------------------------------------------------------------------------------
// minimal version (when scrolled down)
// -----------------------------------------------------------------------------------------

body[data-sticky-menu-scroll='down'] {
    div#nav-sticky {
        top:-$theme-nav-sticky-bar-h-desktop;
    }
}