
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

.paging {

    @include ul-reset();
    display:inline-block;

    li {
        float:left;
    }

    margin-left:-8px;
    font-weight:bold;
    padding:30px 0;
    vertical-align:top;

    span {
        display:inline-block;
        height:36px;
        vertical-align:top;
    }

    span.b-next,
    span.b-back {

    }

    .b-back-first,
    .b-next-last,
    .b-back,
    .b-next {
        color:transparent;
        width:30px;

        &:after {
            position: absolute;
            top: 14px;
            left: 8px;
            content: "";
            display: inline-block;
            width: 9px;
            height: 9px;
            border-right: 2px solid black;
            border-top: 2px solid black;
            transform: rotate(45deg);
        }

        &.arrow-disabled {
 
            &:before,
            &:after {
                opacity:0.25;
            }
        }
    }

    .b-back-first,
    .b-back {
        transform: scaleX(-1);
    }

    .b-back-first,
    .b-next-last {

        &:after {
            left: 4px;
        }

        &:before {
            position: absolute;
            top: 14px;
            left:11px;
            content: "";
            display: inline-block;
            width: 9px;
            height: 9px;
            border-right: 2px solid black;
            border-top: 2px solid black;
            transform: rotate(45deg);
        }
    }

    .current-page {

    }

    .page-numbers {
        margin:0 15px;
        background:$theme-brown-color-1;
        display:block;
        color:white;
        border-radius:5px;
        height:36px;
        font-size: $font-size-sm;
        font-weight: normal;
        line-height:36px;   
        padding:0 10px;
    }
}