
/**
 * scss/core/base/angular.scss
 * ----------------------------------------------------------
 * Angular hooks...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* angular [ng-click] selector
------------------------------------------------------------ */
[ng-click], [data-ng-click], [x-ng-click] {
    cursor: pointer;
}

/* angular [ng-cloak] selector
------------------------------------------------------------ */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}