
/**
 * scss/theme/theme.scss
 * ----------------------------------------------------------
 * Theme variables...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2020 Uselab.com
 *
 * ----------------------------------------------------------
 *
 */

/* these values are needed to be able to scale the main-wrapper to 100% height */
$nav-top-height-sm: 60px;
$nav-top-height-md: 92px;
$footer-height: 80px;