
/**
 * scss/core/components/carousel
 * ----------------------------------------------------------
 * Carousel component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */



/* reduce loading 'flickering' */
.slick-slider.carousel {


    .column:first-child {
       margin-bottom:0;
    }

    > div {
        height:0;
        opacity:0;
    }
    > div:first-child {
        height:auto;
    }
}

.slick-slider.carousel.slick-initialized {
    > div {
        height:auto;
        opacity:1;
    }
}

.slick-slider.carousel {

    width:calc(100% + 30px);
    left:-15px;

    .youtube-player-wrapper {
        width:100%;
    }
}

.slick-dotted.slick-slider {
    margin:0;
}

.slick-slider.carousel {

    .slick-slide {
        outline: none !important;
    }
    overflow: hidden;
    padding: 0 0 50px 0;

    .col-lg-6.column:first-child {
      //  padding-bottom:30px;
    }
    
    .image {
        width:100%;
        img.slimmage {
            width:100%;
            margin-bottom:0;
        }
    }


    h2 {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }

    .slick-slide {
        text-align:left;
        overflow: hidden;
        padding-bottom: 10px; /* ipad fix */
    }

    .slick-dots {
        
        width:100%;
        top:auto;
        bottom: 0px;
        display:inline-block;
        height:35px;
        position: absolute;

        li {
            width:35px; height:35px;
        }

        li button {

            &:before {
                display:none;
            }
            
            width:35px; height:35px;
            background:white;
            font-size:14px;
            color:black;
            border-radius: 5px;
        }

        li.slick-active button {
	        background-color: #6F5F50;
            color:white;
        }

    }
}


@media (min-width: $theme-screen-sm) {

    .slick-slider.carousel {
        p {
            a[class^="btn-style-"] {
                display: inline-block;
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: $theme-screen-md) {

    .slick-slider.carousel {
        
 
        padding: 0;

        .col-lg-6.column:first-child {
            padding-bottom:70px;
        }

        .slick-dots {
            
            left:auto;
            right: 50%;
            width:auto;
            top:auto;
            bottom: 40px;
            display:inline-block;
            height:60px;
            margin-right:5px;
            position: absolute;
    
            li {
                width:35px; height:35px;
            }

            li button {

                &:before {
                    display:none;
                }
            
                width:35px; height:35px;

                background:white;
                font-size:14px;
                color:black;
                border-radius: 5px;
            }

            li.slick-active button {
	            background-color: #6F5F50;
                color:white;
            }

        }

    }
}

.slick-slider.carousel[data-num-items="1"] {
    .slick-dots {
        display:none;
    }
}