
/**
 * core/base/btn/btn.scss
 * ----------------------------------------------------------
 * Button styles, which depends on the Bootstrap classes...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* btn
------------------------------------------------------------ */
.btn-style-1, /* needed for RTE where you can only define a single class for an element. btn.btn-default isn't possible, so we use .btn-default */
.btn-style-2,
.btn-style-3,
.btn-style-4,
.btn-style-5,
.btn-style-6,
.btn-style-7,
.btn-style-8,
.btn,
.btn.primary,
.btn-secondary,
.btn-quaternary,
.btn-default {
  @include button-base($theme-btn-base-border-radius, true);
  border: 0;
}

// @debug nth($theme-btn-default-bg, $theme-btn-state-idle);

/* default
------------------------------------------------------------ */
.btn.primary,
.btn-default,
.btn-primary,
a.btn-style-1:not([href]),  /* overrule bootstap inherit */
.btn-style-1,
.btn.btn-style-1 {

    @include button-background(
        nth($theme-btn-style-1-bg, $theme-btn-state-idle), 
        nth($theme-btn-style-1-bg, $theme-btn-state-hover),
        nth($theme-btn-style-1-bg, $theme-btn-state-focus),
        nth($theme-btn-style-1-bg, $theme-btn-state-active)
    );

    @include button-border(
        nth($theme-btn-style-1-border, $theme-btn-state-idle), 
        nth($theme-btn-style-1-border, $theme-btn-state-hover),
        nth($theme-btn-style-1-border, $theme-btn-state-focus),
        nth($theme-btn-style-1-border, $theme-btn-state-active),
        nth($theme-btn-style-1-border, 5)
    );

    @include button-text(
        nth($theme-btn-style-1-text, $theme-btn-state-idle), 
        nth($theme-btn-style-1-text, $theme-btn-state-hover),
        nth($theme-btn-style-1-text, $theme-btn-state-focus),
        nth($theme-btn-style-1-text, $theme-btn-state-active),
        nth($theme-btn-style-1-text, 5)
    );

}

/* primary
------------------------------------------------------------ */
    a.btn-style-2:not([href]),  /* overrule bootstap inherit */
    .btn-style-2,
    .btn.btn-style-2 {
        @include button-background(
            nth($theme-btn-style-2-bg, $theme-btn-state-idle), 
            nth($theme-btn-style-2-bg, $theme-btn-state-hover),
            nth($theme-btn-style-2-bg, $theme-btn-state-focus),
            nth($theme-btn-style-2-bg, $theme-btn-state-active)
        );

        @include button-border(
            nth($theme-btn-style-2-border, $theme-btn-state-idle), 
            nth($theme-btn-style-2-border, $theme-btn-state-hover),
            nth($theme-btn-style-2-border, $theme-btn-state-focus),
            nth($theme-btn-style-2-border, $theme-btn-state-active),
            nth($theme-btn-style-2-border, 5)
        );

        @include button-text(
            nth($theme-btn-style-2-text, $theme-btn-state-idle), 
            nth($theme-btn-style-2-text, $theme-btn-state-hover),
            nth($theme-btn-style-2-text, $theme-btn-state-focus),
            nth($theme-btn-style-2-text, $theme-btn-state-active),
            nth($theme-btn-style-2-text, 5)
        );
    }


/* secondary
------------------------------------------------------------ */
a.btn-style-3:not([href]),  /* overrule bootstap inherit */
.btn-style-3,
.btn.btn-secondary {

    @include button-background(
        nth($theme-btn-style-3-bg, $theme-btn-state-idle), 
        nth($theme-btn-style-3-bg, $theme-btn-state-hover),
        nth($theme-btn-style-3-bg, $theme-btn-state-focus),
        nth($theme-btn-style-3-bg, $theme-btn-state-active)
    );

    @include button-border(
        nth($theme-btn-style-3-border, $theme-btn-state-idle), 
        nth($theme-btn-style-3-border, $theme-btn-state-hover),
        nth($theme-btn-style-3-border, $theme-btn-state-focus),
        nth($theme-btn-style-3-border, $theme-btn-state-active),
        nth($theme-btn-style-3-border, 5)
    );

    @include button-text(
        nth($theme-btn-style-3-text, $theme-btn-state-idle), 
        nth($theme-btn-style-3-text, $theme-btn-state-hover),
        nth($theme-btn-style-3-text, $theme-btn-state-focus),
        nth($theme-btn-style-3-text, $theme-btn-state-active),
        nth($theme-btn-style-3-text, 5)
    );

}

/* tertiary
------------------------------------------------------------ */
a.btn-style-4:not([href]),  /* overrule bootstap inherit */
.btn-style-4,
.btn.btn-style-4 {

    @include button-background(
        nth($theme-btn-style-4-bg, $theme-btn-state-idle), 
        nth($theme-btn-style-4-bg, $theme-btn-state-hover),
        nth($theme-btn-style-4-bg, $theme-btn-state-focus),
        nth($theme-btn-style-4-bg, $theme-btn-state-active)
    );

    @include button-border(
        nth($theme-btn-style-4-border, $theme-btn-state-idle), 
        nth($theme-btn-style-4-border, $theme-btn-state-hover),
        nth($theme-btn-style-4-border, $theme-btn-state-focus),
        nth($theme-btn-style-4-border, $theme-btn-state-active),
        nth($theme-btn-style-4-border, 5)
    );

    @include button-text(
        nth($theme-btn-style-4-text, $theme-btn-state-idle), 
        nth($theme-btn-style-4-text, $theme-btn-state-hover),
        nth($theme-btn-style-4-text, $theme-btn-state-focus),
        nth($theme-btn-style-4-text, $theme-btn-state-active),
        nth($theme-btn-style-4-text, 5)
    );
}

/* quaternary 
------------------------------------------------------------ */
a.btn-style-5:not([href]),  /* overrule bootstap inherit */
.btn-style-5,
.btn.btn-style-5  {

    @include button-background(
        nth($theme-btn-style-5-bg, $theme-btn-state-idle), 
        nth($theme-btn-style-5-bg, $theme-btn-state-hover),
        nth($theme-btn-style-5-bg, $theme-btn-state-focus),
        nth($theme-btn-style-5-bg, $theme-btn-state-active)
    );

    @include button-border(
        nth($theme-btn-style-5-border, $theme-btn-state-idle), 
        nth($theme-btn-style-5-border, $theme-btn-state-hover),
        nth($theme-btn-style-5-border, $theme-btn-state-focus),
        nth($theme-btn-style-5-border, $theme-btn-state-active),
        nth($theme-btn-style-5-border, 5)
    );

    @include button-text(
        nth($theme-btn-style-5-text, $theme-btn-state-idle), 
        nth($theme-btn-style-5-text, $theme-btn-state-hover),
        nth($theme-btn-style-5-text, $theme-btn-state-focus),
        nth($theme-btn-style-5-text, $theme-btn-state-active),
        nth($theme-btn-style-5-text, 5)
    );
}

/* quinary 
------------------------------------------------------------ */
a.btn-style-6:not([href]),  /* overrule bootstap inherit */
.btn-style-6,
.btn.btn-style-6  {

    @include button-background(
        nth($theme-btn-style-6-bg, $theme-btn-state-idle), 
        nth($theme-btn-style-6-bg, $theme-btn-state-hover),
        nth($theme-btn-style-6-bg, $theme-btn-state-focus),
        nth($theme-btn-style-6-bg, $theme-btn-state-active)
    );

    @include button-border(
        nth($theme-btn-style-6-border, $theme-btn-state-idle), 
        nth($theme-btn-style-6-border, $theme-btn-state-hover),
        nth($theme-btn-style-6-border, $theme-btn-state-focus),
        nth($theme-btn-style-6-border, $theme-btn-state-active),
        nth($theme-btn-style-6-border, 5)
    );

    @include button-text(
        nth($theme-btn-style-6-text, $theme-btn-state-idle), 
        nth($theme-btn-style-6-text, $theme-btn-state-hover),
        nth($theme-btn-style-6-text, $theme-btn-state-focus),
        nth($theme-btn-style-6-text, $theme-btn-state-active),
        nth($theme-btn-style-6-text, 5)
    );
}


/*  
------------------------------------------------------------ */
a.btn-style-7:not([href]),  /* overrule bootstap inherit */
.btn-style-7,
.btn.btn-style-7  {

    @include button-background(
        nth($theme-btn-style-7-bg, $theme-btn-state-idle), 
        nth($theme-btn-style-7-bg, $theme-btn-state-hover),
        nth($theme-btn-style-7-bg, $theme-btn-state-focus),
        nth($theme-btn-style-7-bg, $theme-btn-state-active)
    );

    @include button-border(
        nth($theme-btn-style-7-border, $theme-btn-state-idle), 
        nth($theme-btn-style-7-border, $theme-btn-state-hover),
        nth($theme-btn-style-7-border, $theme-btn-state-focus),
        nth($theme-btn-style-7-border, $theme-btn-state-active),
        nth($theme-btn-style-7-border, 5)
    );

    @include button-text(
        nth($theme-btn-style-7-text, $theme-btn-state-idle), 
        nth($theme-btn-style-7-text, $theme-btn-state-hover),
        nth($theme-btn-style-7-text, $theme-btn-state-focus),
        nth($theme-btn-style-7-text, $theme-btn-state-active),
        nth($theme-btn-style-7-text, 5)
    );
}

/*  
------------------------------------------------------------ */
a.btn-style-8:not([href]),  /* overrule bootstap inherit */
.btn-style-8,
.btn.btn-style-8  {

    @include button-background(
        nth($theme-btn-style-8-bg, $theme-btn-state-idle), 
        nth($theme-btn-style-8-bg, $theme-btn-state-hover),
        nth($theme-btn-style-8-bg, $theme-btn-state-focus),
        nth($theme-btn-style-8-bg, $theme-btn-state-active)
    );

    @include button-border(
        nth($theme-btn-style-8-border, $theme-btn-state-idle), 
        nth($theme-btn-style-8-border, $theme-btn-state-hover),
        nth($theme-btn-style-8-border, $theme-btn-state-focus),
        nth($theme-btn-style-8-border, $theme-btn-state-active),
        nth($theme-btn-style-8-border, 5)
    );

    @include button-text(
        nth($theme-btn-style-8-text, $theme-btn-state-idle), 
        nth($theme-btn-style-8-text, $theme-btn-state-hover),
        nth($theme-btn-style-8-text, $theme-btn-state-focus),
        nth($theme-btn-style-8-text, $theme-btn-state-active),
        nth($theme-btn-style-8-text, 5)
    );
}
/* state: info
------------------------------------------------------------ */
.btn.btn-info {
    @include button-background($theme-color-state-info, darken($theme-color-state-info, 5%), darken($theme-color-state-info, 10%), darken($theme-color-state-info, 15%));
    @include button-font(#fff);
}

/* state: success
------------------------------------------------------------ */
.btn.btn-success {
    @include button-background($theme-color-state-success, darken($theme-color-state-success, 5%), darken($theme-color-state-success, 10%), darken($theme-color-state-success, 15%));
    @include button-font(#fff);
}

/* state: warning
------------------------------------------------------------ */
.btn.btn-warning {
    @include button-background($theme-color-state-warning, darken($theme-color-state-warning, 5%), darken($theme-color-state-warning, 10%), darken($theme-color-state-warning, 15%));
    @include button-font(#fff);
}

/* state: danger
------------------------------------------------------------ */
.btn.btn-danger {
    @include button-background($theme-color-state-danger, darken($theme-color-state-danger, 5%), darken($theme-color-state-danger, 10%), darken($theme-color-state-danger, 15%));
    @include button-font(#fff);
}

/* pure
------------------------------------------------------------ */
// no styles, but same padding, can be used in a horizontal list of buttons...
.btn.btn-pure {
    @include button-base(0, true);
    @include button-background(transparant, transparant, transparant, transparant);
    @include button-font(black);
    padding: 10px 5px;
    border: 0;
}