/**
 * scss/site/base/base.scss
 * ----------------------------------------------------------
 * Base and generic styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

 html,
 body {
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
 }

#app {
  height: 100%;
  min-height: 100%;
  position: relative;
}


small, .small {
  font-size: $theme-font-size-s;
  font-weight: normal;
}