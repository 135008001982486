
/**
 * scss/core/components/hamburger.scss
 * ----------------------------------------------------------
 * Hamburger component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.hamburger {
    .line {
        height: 3px;
        background-color: #ecf0f1;
        display: block;
        margin: 8px auto;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    &:hover {
        cursor: pointer;
    }
}

.hamburger.hb-active {
    .line:nth-child(2) {
        opacity: 0;
    }
    .line:nth-child(1) {
        -webkit-transform: translateY(11px) rotate(45deg);
        -ms-transform: translateY(11px) rotate(45deg);
        -o-transform: translateY(11px) rotate(45deg);
        transform: translateY(11px) rotate(45deg);
    }
    .line:nth-child(3) {
        -webkit-transform: translateY(-11px) rotate(-45deg);
        -ms-transform: translateY(-11px) rotate(-45deg);
        -o-transform: translateY(-11px) rotate(-45deg);
        transform: translateY(-11px) rotate(-45deg);
    }
}



