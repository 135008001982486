
/**
 * scss/core/base/form/input-file.scss
 * ----------------------------------------------------------
 * File input...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.input-container input[type="file"] {
    opacity: 0;
}

.input-container.input-file {
    position: relative;
    border: 0;
    border-radius: 0;

    input[type="file"] {
        width: 100%;
        height: 100%;
        padding: 0;
        border: 0;
        z-index: 2;
        position: absolute;
    }

    .custom-file-upload {
        padding: $theme-file-input-padding;
        border: $theme-form-element-border solid $theme-form-color-default;
        border-radius: $theme-form-element-radius;
        font-size: $theme-body-font-size;
        z-index: 1;

        &:after {
            border-radius: $theme-btn-base-border-radius;
            content: $theme-file-input-button-text;
            font-size: $theme-file-input-button-font-size;
            background: $theme-file-input-button-background;
            padding: $theme-file-input-button-padding;
            color: $theme-file-input-button-font-color;
            position: absolute;
            right: $theme-file-input-button-position-right;
            height: calc(100% - 6px);
            top: 3px;
        }
    }
}

.input-container.input-error .custom-file-upload {
    border-color: $theme-form-color-error;
}