
/**
 * scss/core/components/throbber.scss
 * ----------------------------------------------------------
 * Throbber component...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

$theme-tooltip-background: #55d769 !default;

.tooltip {
    display: block;
    left: 0;
    right: 0;
    position: absolute;

    .tooltip-inner {
        background: $theme-tooltip-background;
        padding: 15px 25px;
        max-width: none;
    }
}

.tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100);
}
.tooltip.top {
    margin-top: -6px;
}
.tooltip.bottom {
    margin-top: 6px;
}

.tooltip.top .tooltip-arrow {
    border-top-color: $theme-tooltip-background;
}
.tooltip.right .tooltip-arrow {
    border-right-color: $theme-tooltip-background;
}
.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $theme-tooltip-background;
}