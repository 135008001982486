
/**
 * scss/myfudura.scss
 * ----------------------------------------------------------
 * Styles specific for the myfudura environment
 *
 * @type       Sass
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

/* site 
------------------------------------------------------------ */
@import "shared/shared";
@import "myfudura/myfudura";

/* overrule any different box-sizing settings, for example the Umbraco RTE */
* {
    box-sizing: border-box;
}
