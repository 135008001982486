
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.block-image-text.block-product,
.block-product,
.block-product.schaduw, /* umbraco classname */
.block-product.primary {

    background: $theme-brand-color-gray-3;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);

    .footer {
        position: relative;
        background-color:white;
        padding-top:18px;
        padding-bottom:18px;
    }
    
    .link-arrow-2 {
        width:100%;
        padding-right:20px;
        &:after {
            position:absolute;
            right:30px;
            top:25px;
        }
    }

    .image {
        text-align:center;
        padding:20px;

        img.slimmage {
            height:160px!important;
        }
    }
}

.block-product.geen-schaduw, /* umbraco classname */
.block-product.secondary {
    box-shadow: 0 0 0 0;
    background: transparent;
    div.footer,
    div.text {
        padding-left:0;
        padding-right:0;
    }
    div.text {
      //  padding-top:0;
    }
}

.block-product { 

    .header-img {
        img, img.slimmage {
            margin:0 0 1em 0;
            width:100px;
            height:100px!important;
        }
    }
}


@media (min-width:  map-get($grid-breakpoints, "xl")) {

    .block-product { 

        .header-img {
        
            position:relative;
            min-height:120px;
            padding-bottom:20px;
            padding-left:120px;
        
            h1, h2, h3, h4, h5 {
                margin-bottom:0;
            }

            img, img.slimmage {
                position:absolute;
                left:0;
                top:0;
                margin:0;
                width:100px;
                height:100px!important;
            }
        }
    }
}

.section-gray {
    .block-product {
        background-color:white;
    }
}