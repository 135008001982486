
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 * Header layout element...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

header.header-image {

    background-color:$theme-brand-color-gray-3;

    .container {
        padding-bottom:0;
        padding-top: 20px;

        .image {
            min-height: 200px;
            position: relative;
        }

        .align-bottom {
            position: absolute;
            bottom: 30px;
            left: 30px;
            transform: none;

            h1, p {
                margin: 0;
                color: white;
            }
            h1 + p {
                margin-top: 1em;
            }
        }
    }
}

@media (min-width: $theme-screen-md) {
    header.header-image {
        .container {
            .image {
                min-height: 350px;
            }
        }
    }
}