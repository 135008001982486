
/**
 * scss/core/layout/footer.scss
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts  <pj.arts@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

footer {
  display: block;
  border-top: 1px solid $theme-brown-color-2;
  padding: 25px 0;

  height: $footer-height;

  text-align: center;

  ul.list-inline {
    li {
      margin: 0 5px;
      a {
        text-decoration: none;
        font-size: $theme-font-size-s;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (min-width: $theme-screen-md) {
  footer {
    padding: 30px 100px;
    ul.list-inline {
      li {
        margin: 0 10px;
      }
    }
  }
}