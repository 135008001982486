
/**
 * scss/core/base/grid-container.scss
 * ----------------------------------------------------------
 * Custom grid and Bootstrap grid styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 * Important note:
 * ----------------------------------------------------------
 * We depend on the Bootstrap .col-**-** structure!
 *
 */

@media (min-width: $theme-screen-xl) {

    //.container-breadcrumb.container,


    header .container {
        width: 1400px;
    }
}
