
/**
 * scss/core/theme.scss
 * ----------------------------------------------------------
 * Theme variables...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

// -----------------------------------------------------------------------------------------
// BRANDING
// -----------------------------------------------------------------------------------------

$theme-brand-color-primary:                     #3d3d3d !default;
$theme-brand-color-secondary:                   #000000 !default;
$theme-brand-color-tertiary:                    #000000 !default;
$theme-brand-color-light:                       #e7e7e7 !default;
$theme-brand-color-dark:                        #adadad !default;

/* main theme colors */
$theme-brand-color-blue-1:                      #0B243C !default;
$theme-brand-color-blue-2:                      #1F466B !default;
$theme-brand-color-blue-3:                      #5082BF !default;
$theme-brand-color-red-1:                       #FF0000 !default;
$theme-brand-color-red-2:                       #FF0000 !default;
$theme-brand-color-gray-1:                      #E8ECF0 !default;
$theme-brand-color-gray-2:                      #979797 !default;
$theme-brand-color-green-1:                     #5AA67C !default;
$theme-brand-color-green-2:                     #5AA67C !default;
$theme-brand-color-yellow-1:                    #F8C81C !default;
$theme-brand-color-yellow-2:                    #F8C81C !default;

$theme-brand-font-family:                       Arial, sans-serif !default;

// -----------------------------------------------------------------------------------------
// BREAKPOINTS
// -----------------------------------------------------------------------------------------

$theme-screen-sm:                               768px !default;
$theme-screen-md:                               992px !default;
$theme-screen-lg:                               1200px !default;
$theme-screen-xl:                               1500px !default;

// defined max so they don't overlap when necessary...
$theme-screen-xs-max:                           ($theme-screen-sm - 1) !default;
$theme-screen-sm-max:                           ($theme-screen-md - 1) !default;
$theme-screen-md-max:                           ($theme-screen-lg - 1) !default;

// -----------------------------------------------------------------------------------------
// Z-INDEXES
// -----------------------------------------------------------------------------------------

$theme-z-index-overlay:                         20000 !default; // used for alert, spinner, modal

// -----------------------------------------------------------------------------------------
// COLORS
// -----------------------------------------------------------------------------------------

/* state colors
-------------------------------------------------------------------------------------------- */
$theme-color-state-default:                     #f9f9f9 !default; // state: default
$theme-color-state-info:                        #00afff !default; // state: info
$theme-color-state-success:                     #55d769 !default; // state: success
$theme-color-state-warning:                     #ffcd2d !default; // state: warning
$theme-color-state-danger:                      #ff191e !default; // state: danger

// -----------------------------------------------------------------------------------------
// FONT
// -----------------------------------------------------------------------------------------

/* font definitions
-------------------------------------------------------------------------------------------- */
$theme-font-light:                              $theme-brand-font-family !default;
$theme-font-regular:                            $theme-brand-font-family !default;
$theme-font-bold:                               $theme-brand-font-family !default;

/* sizes
-------------------------------------------------------------------------------------------- */
$theme-font-size-xs:                            12px !default;
$theme-font-size-s:                             14px !default;
$theme-font-size-m:                             16px !default;
$theme-font-size-l:                             18px !default;
$theme-font-size-xl:                            20px !default;
$theme-font-size-xxl:                           30px !default;

// -----------------------------------------------------------------------------------------
// BASE
// -----------------------------------------------------------------------------------------

/* body
-------------------------------------------------------------------------------------------- */
$theme-body-font-family:                        $theme-brand-font-family !default;
$theme-body-font-size:                          $theme-font-size-m !default;
$theme-body-font-color:                         black !default;
$theme-body-font-weight:                        'normal' !default;
$theme-body-line-height:                        1.428571429 !default; // copied from Bootstrap...
$theme-body-background:                         #fff !default;

/* headings
-------------------------------------------------------------------------------------------- */
$theme-h1-font-family:                          $theme-brand-font-family !default;
$theme-h2-font-family:                          $theme-brand-font-family !default;
$theme-h3-font-family:                          $theme-brand-font-family !default;
$theme-h4-font-family:                          $theme-brand-font-family !default;
$theme-h5-font-family:                          $theme-brand-font-family !default;

// sizes
$theme-h1-font-size:                            36px !default;
$theme-h2-font-size:                            30px !default;
$theme-h3-font-size:                            26px !default;
$theme-h4-font-size:                            20px !default;
$theme-h5-font-size:                            18px !default;

// colors
$theme-h1-font-color:                           $theme-brand-color-primary !default;
$theme-h2-font-color:                           $theme-brand-color-primary !default;
$theme-h3-font-color:                           $theme-brand-color-primary !default;
$theme-h4-font-color:                           $theme-brand-color-primary !default;
$theme-h5-font-color:                           $theme-brand-color-primary !default;

// weights
$theme-h1-font-weight:                            bold !default;
$theme-h2-font-weight:                            bold !default;
$theme-h3-font-weight:                            bold !default;
$theme-h4-font-weight:                            bold !default;
$theme-h5-font-weight:                            bold !default;

/* form
-------------------------------------------------------------------------------------------- */
// wrapper padding
$theme-form-wrapper-background:                 $theme-brand-color-light !default;
$theme-form-wrapper-padding:                    40px !default;
$theme-form-wrapper-margin:                     0 0 40px 0 !default;

$theme-form-font-size:                          $theme-body-font-size !default;
$theme-form-error-font-size:                    16px;

// required indicator
$theme-form-required-color:                     #55d769 !default;

// option sprites
$theme-form-sprite-select:                      '/assets/img/icon-sprite-form.svg#select' !default;
$theme-form-sprite-radio-idle:                  '/assets/img/icon-sprite-form.svg#radio-idle' !default;
$theme-form-sprite-radio-checked:               '/assets/img/icon-sprite-form.svg#radio-checked' !default;
$theme-form-sprite-checkbox-idle:               '/assets/img/icon-sprite-form.svg#checkbox-idle' !default;
$theme-form-sprite-checkbox-checked:            '/assets/img/icon-sprite-form.svg#checkbox-checked' !default;

// form default color
$theme-form-color-default:                      #e1e1e1 !default;
$theme-form-color-error:                        $theme-color-state-danger !default;
$theme-form-color-placeholder:                  #9B9B9B !default;

// form row
$theme-form-row-margin-bottom:                  15px !default;

// element
$theme-form-element-padding:                    8px !default;
$theme-form-element-border:                     1px !default;
$theme-form-element-radius:                     2px !default;
$theme-form-element-height:                     24px !default;

// label
$theme-form-label-font-weight:                  normal !default;

// file input
$theme-file-input-padding:                      8px !default;
$theme-file-input-button-text:                  'Kies bestand' !default;
$theme-file-input-button-padding:               7px 12px !default;
$theme-file-input-button-background:            $theme-brand-color-dark !default;
$theme-file-input-button-font-size:             $theme-font-size-m !default;
$theme-file-input-button-font-color:            white !default;
$theme-file-input-button-position-right:        3px !default;

/* hyperlink
-------------------------------------------------------------------------------------------- */
$theme-hyperlink-color:                         #00afff !default;
$theme-hyperlink-color-hover:                   #00afff !default;
$theme-hyperlink-color-active:                  #00afff !default;

/* button [base]
-------------------------------------------------------------------------------------------- */
$theme-btn-base-border-radius:                  1px !default;

/* button [types]
-------------------------------------------------------------------------------------------- */

$theme-btn-state-idle: 1;
$theme-btn-state-hover: 2;
$theme-btn-state-focus: 3;
$theme-btn-state-active: 4;

$theme-btn-style-1-bg: #f9f9f9, #f9f9f9, #f9f9f9, #f9f9f9 !default;
$theme-btn-style-1-border: #f9f9f9, #f9f9f9, #f9f9f9, #f9f9f9, 1px solid !default;
$theme-btn-style-2-text: white, white, white, white, italic small-caps normal 13px/150% Arial, Helvetica, sans-serif !default;

$theme-btn-style-2-bg: white, white, white, white !default;
$theme-btn-style-2-border: #f9f9f9, #f9f9f9, #f9f9f9, #f9f9f9, 1px solid !default;
$theme-btn-style-2-text:  #f9f9f9, #f9f9f9, #f9f9f9, #f9f9f9, italic small-caps normal 13px/150% Arial, Helvetica, sans-serif !default;

$theme-btn-style-3-bg: #55d769, #55d769, #55d769, #55d769 !default;
$theme-btn-style-3-border: #55d769, #55d769, #55d769, #55d769, 1px solid !default;
$theme-btn-style-3-text: white, white, white, white, italic small-caps normal 13px/150% Arial, Helvetica, sans-serif !default;

$theme-btn-style-4-bg: #55d769, #55d769, #55d769, #55d769 !default;
$theme-btn-style-4-border: #55d769, #55d769, #55d769, #55d769, 1px solid !default;
$theme-btn-style-4-text: white, white, white, white, italic small-caps normal 13px/150% Arial, Helvetica, sans-serif !default;

$theme-btn-style-5-bg: #55d769, #55d769, #55d769, #55d769 !default;
$theme-btn-style-5-border: #55d769, #55d769, #55d769, #55d769, 1px solid !default;
$theme-btn-style-5-text: white, white, white, white, italic small-caps normal 13px/150% Arial, Helvetica, sans-serif !default;

$theme-btn-style-6-bg: #55d769, #55d769, #55d769, #55d769 !default;
$theme-btn-style-6-border: #55d769, #55d769, #55d769, #55d769, 1px solid !default;
$theme-btn-style-6-text: white, white, white, white, italic small-caps normal 13px/150% Arial, Helvetica, sans-serif !default;

// @debug nth($theme-btn-default-bg, $theme-btn-state-idle);

/* icon button
-------------------------------------------------------------------------------------------- */
$theme-btn-icon-default:                        #f9f9f9 !default;
$theme-btn-icon-default-hover:                  darken(#f9f9f9, 5%) !default;
$theme-btn-icon-default-focus:                  darken(#f9f9f9, 10%) !default;
$theme-btn-icon-default-active:                 darken(#f9f9f9, 15%) !default;

$theme-btn-icon-primary:                        #00afff !default;
$theme-btn-icon-primary-hover:                  darken(#00afff, 5%) !default;
$theme-btn-icon-primary-focus:                  darken(#00afff, 10%) !default;
$theme-btn-icon-primary-active:                 darken(#00afff, 15%) !default;

$theme-btn-icon-secondary:                      #55d769 !default;
$theme-btn-icon-secondary-hover:                darken(#55d769, 5%) !default;
$theme-btn-icon-secondary-focus:                darken(#55d769, 5%) !default;
$theme-btn-icon-secondary-active:               darken(#55d769, 5%) !default;

/* unordered lists
-------------------------------------------------------------------------------------------- */
$theme-ul-primary:                              $theme-brand-color-primary !default;
$theme-ul-secondary:                            #00afff !default;
$theme-ul-third:                                #55d769 !default;
$theme-ul-arrow-image:                          '/assets/img/arrow-right.svg' !default;

// -----------------------------------------------------------------------------------------
// LAYOUT
// -----------------------------------------------------------------------------------------

/* grid section
-------------------------------------------------------------------------------------------- */
$theme-section-border:                          1px solid gray !default;

// padding
$theme-section-padding-sm:                      30px !default;
$theme-section-padding-md:                      60px !default;

$theme-block-padding-sm:                        30px !default;
$theme-block-padding-md:                        30px !default;

// colors
$theme-section-color-default:                   white !default;
$theme-section-color-primary:                   white !default;
$theme-section-color-secondary:                 $theme-brand-color-light !default;

$theme-section-min-height-medium:               200px !default;
$theme-section-min-height-large:                300px !default;

$theme-section-min-height-md-medium:            400px !default;
$theme-section-min-height-md-large:             600px !default;

/* header
-------------------------------------------------------------------------------------------- */
$theme-header-background:                       $theme-brand-color-dark !default;

/* footer
-------------------------------------------------------------------------------------------- */
$theme-footer-background:                       $theme-brand-color-light !default;