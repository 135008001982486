﻿/**
 * site/component/scroll-container
 * ----------------------------------------------------------
 * Div to make a table scrollable on smaller screens...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2015 Uselab.com
 *
 * Important note:
 * ----------------------------------------------------------
 * This div is created by the Angular directive table-scroll!
 *
 */

.scroll-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 20px;

    table {
        font-size: 0.95em;
        word-wrap: break-word;
        width: 100%;
        table-layout: fixed;
        margin-bottom: 0;

        th {
            word-wrap: normal;
        }
    }
}

@media (max-width: $theme-screen-xs-max) {
    .scroll-container {
        table {
            width: 300%; 
        }
    }
}

@media (max-width: $theme-screen-sm-max) and (min-width: $theme-screen-sm) {
    .scroll-container {
        table {
            width: 200%;
        }
    }
}