
/**
 * scss/core/components/meta-data
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     K van den Broek <k.vandenbroek@uselab.com>
 * @copyright  2018 Uselab.com
 *
 */

ul.meta-data {
    @include ul-reset();
    display: block;

    li,
    li:after {
        display: inline-block;
        color: $theme-brand-color-blue-3;
    }

    li:after {
        content: '|';
        margin: 0 10px;
    }

    li + li {
        margin-left: -4px;
    }

    li:last-child:after {
        display: none;
    }
}