/**
 * scss/site/base/hyperlink
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

a.link-back {
  &,
  &:hover {
    font-size: $theme-font-size-xs;
    color: $theme-brand-color-blue-4;
    text-decoration: none;

    &:before {
      display: block;
      position: relative;
      top: -1px;
      content: '';
      margin-right: 10px;
      margin-left: 3px;
      border: solid $theme-brand-color-orange-1;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  &:hover {
    text-decoration: underline;
  }
}
