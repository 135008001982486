
/**
 * scss/core/layout/header.scss
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts  <pj.arts@uselab.com>
 * @copyright  2019 Uselab.com
 *
 */

.header {
  &--myfudura {
    .column {
      > div {
        border-bottom: 1px solid $theme-brand-color-orange-2;
        padding: 30px 0;
        margin-bottom: 30px;
      }
    }
  }
}

@media (min-width: $theme-screen-md) {
  .header {
    &--myfudura {
      .column {
        > div {
          padding: 0 0 30px 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}
